import { Subject } from 'rxjs';

import { ToastProps } from '../components/element/Toasts/ToastsItem';

type ToastServiceProps = ToastProps & { addedOn: string };

let toasts: Array<ToastServiceProps> = [];
const ToastEvent = new Subject();

export const ToastUtil = {
  listen(evt: () => void) {
    return ToastEvent.subscribe({ next: () => evt() });
  },
  getAll() {
    return toasts;
  },
  add(toastArgs: ToastProps) {
    const addedOn = new Date().toISOString() + Math.random();
    toasts.unshift({ ...toastArgs, addedOn });
    ToastEvent.next(toastArgs);
    return addedOn;
  },
  remove(removeToastArgs: ToastServiceProps) {
    toasts = toasts.filter(toast => toast.addedOn !== removeToastArgs.addedOn);
    ToastEvent.next(removeToastArgs);
  },
  removeAll() {
    toasts = [];
    ToastEvent.next(null);
  },
};
