import {
  SubmissionCandidate,
  Candidate,
  AfcSubmission,
  AfcSubmissionPosition,
  AfcSubmissionCandidateOpportunity,
} from '@axiom/validation';
import { CurrencyUtil } from '@axiom/utils';
import { CandidateOpportunitiesConst, CurrencySymbolTypes } from '@axiom/const';

const { hourly, daily, weekly } =
  CandidateOpportunitiesConst.DisplayBillingRate;
const { DisplayBillingRate } = CandidateOpportunitiesConst;

const getRateValue = ({
  proposedHourlyRate,
  displayBillingRate,
  billingHoursPerDay,
  currency,
}: {
  proposedHourlyRate: number;
  displayBillingRate: string;
  billingHoursPerDay: number;
  currency: CurrencySymbolTypes;
}) => {
  let price = proposedHourlyRate;
  let increment = '';
  if (
    !price ||
    price < 0 ||
    Number.isNaN(price) ||
    displayBillingRate === DisplayBillingRate.none
  ) {
    return '--';
  }

  switch (displayBillingRate) {
    case hourly: {
      increment = 'hour';
      break;
    }
    case daily: {
      price *= billingHoursPerDay;
      increment = 'day';
      break;
    }
    case weekly: {
      price *= billingHoursPerDay * 5;
      increment = 'week';
      break;
    }
    default: {
      return null;
    }
  }

  if (
    proposedHourlyRate &&
    displayBillingRate !== DisplayBillingRate.none &&
    (displayBillingRate === DisplayBillingRate.hourly ||
      billingHoursPerDay >= 0)
  ) {
    return `${currency} ${CurrencyUtil.getFormattedCurrency(
      price
    )}/${increment}`;
  }

  return '--';
};

export const CandidateRateUtil = {
  formatCandidateRateLegacy(
    candidate: SubmissionCandidate,
    currency: CurrencySymbolTypes
  ) {
    const {
      displayBillingRate,
      proposedHourlyRate,
      position: { billingHoursPerDay },
    } = candidate;

    return getRateValue({
      proposedHourlyRate,
      displayBillingRate,
      billingHoursPerDay,
      currency,
    });
  },
  formatCandidateRate(
    candidateId: Candidate['id'],
    currency: CurrencySymbolTypes,
    submission: AfcSubmission
  ) {
    let candidateOpp: AfcSubmissionCandidateOpportunity = {};
    const position =
      submission.positions.find((pos: AfcSubmissionPosition) => {
        const data = pos.candidateOpportunities.find(
          (co: AfcSubmissionCandidateOpportunity) =>
            co.candidate.id === candidateId
        );
        if (data) candidateOpp = data;
        return !!data;
      }) || {};
    const { billingHoursPerDay } = position;
    const { displayBillingRate, proposedHourlyRate } = candidateOpp;

    return getRateValue({
      proposedHourlyRate,
      displayBillingRate,
      billingHoursPerDay,
      currency,
    });
  },
};
