import { ZodError, ZodFirstPartyTypeKind, ZodIssue } from 'zod';

import { CustomSchemaTypes, SchemaTypes } from './schema-types';

const diveIntoZodDef = <T>(prop: ZodMergedType & ZodExtendType<T>) => {
  let types = [prop._def.typeName];
  let checks = (prop._def.checks || []) as Array<Record<string, unknown>>;
  let shape = prop.shape || null;
  let arrayType = prop._def.type || null;

  if (prop._def.minLength) {
    checks.push({
      kind: 'min',
      ...prop._def.minLength,
    });
  }
  if (prop._def.maxLength) {
    checks.push({
      kind: 'max',
      ...prop._def.maxLength,
    });
  }

  if (checks.some(check => check.kind === 'email')) {
    types.push(CustomSchemaTypes.SchemaEmail as ZodFirstPartyTypeKind);
  }

  if (prop._customType !== undefined) {
    types.push(prop._customType as ZodFirstPartyTypeKind);
  }

  const { innerType, schema } = prop._def;
  [innerType, schema].forEach(defProp => {
    if (defProp) {
      const {
        types: innerTypes,
        checks: innerChecks,
        shape: innerShape,
        arrayType: innerArrayType,
      } = diveIntoZodDef(defProp);
      types = [...types, ...innerTypes];
      checks = [...checks, ...innerChecks];
      if (innerShape) {
        shape = innerShape;
      }

      if (innerArrayType) {
        arrayType = innerArrayType;
      }
    }
  });

  return { types, checks, shape, arrayType };
};
const formatProperty = <T>(
  prop: ZodMergedType & ZodExtendType<T>
): SchemaDefinitionProperty => {
  const { types, checks, shape, arrayType } = diveIntoZodDef(prop);

  const foundCustomType =
    CustomSchemaTypes?.[
      Object.keys(CustomSchemaTypes).find(type =>
        types.includes(type as ZodFirstPartyTypeKind)
      ) as keyof typeof CustomSchemaTypes
    ];
  const foundSchemaType =
    SchemaTypes?.[
      Object.keys(SchemaTypes).find(type =>
        types.includes(type as ZodFirstPartyTypeKind)
      ) as keyof typeof SchemaTypes
    ];
  const schemaType = foundCustomType || foundSchemaType;
  if (!schemaType) {
    throw new Error(`Cannot find known schema prop: ${types}`);
  }

  const formattedProp = {
    type: schemaType,
    constraints: {
      optional: !!types.some(
        type => type === 'ZodOptional' || type === 'ZodNullable'
      ),
      maxLength: checks.find(check => check.kind === 'max')?.value,
      minLength: checks.find(check => check.kind === 'min')?.value,
    },
    children: null,
  } as SchemaDefinitionProperty;

  if (shape) {
    formattedProp.children = Object.keys(shape).reduce(
      (acc: SchemaDefinitionProperty['children'], propName: string) => {
        acc[propName] = formatProperty<T>(
          shape[propName]
        ) as SchemaDefinitionProperty;

        return acc;
      },
      {}
    );
  }

  if (arrayType) {
    formattedProp.arrayType = formatProperty<T>(arrayType);
  }

  return formattedProp;
};

const setStructure = (
  structure: Record<string, unknown> | Array<unknown>,
  keys: Array<string | number>,
  value: unknown
): Record<string, unknown> | Array<unknown> | unknown => {
  if (keys.length === 0) {
    return value;
  }

  const key = keys.shift();

  if (Number.isInteger(key)) {
    const arrKey = key as number;
    if (!Array.isArray(structure)) {
      structure = [];
    }
    structure[arrKey] = setStructure(
      structure[arrKey] as Array<number>,
      keys,
      value
    );
  } else {
    if (!structure) {
      structure = {} as Record<string, unknown>;
    }

    const strutKey = key as string;
    let innerValue = (structure as Record<string, unknown>)[strutKey];
    innerValue = setStructure(
      innerValue as Record<string, unknown>,
      keys,
      value
    );
    (structure as Record<string, unknown>)[strutKey] = innerValue;
  }

  return structure;
};

const formatErrors = (errors: Array<ZodIssue>) => {
  return errors?.reduce((acc: Record<string, string>, err) => {
    let errorMessage = err.message;

    if (
      err.code === 'invalid_type' &&
      err.received === 'null' &&
      err.message.startsWith('Expected')
    ) {
      errorMessage = 'Required';
    }

    if (
      err.code === 'too_big' &&
      err.type === 'string' &&
      err.message.startsWith('String')
    ) {
      errorMessage = `Maximum character limit reached: ${err.maximum}`;
    }

    setStructure(acc, err.path, errorMessage);

    return acc;
  }, {});
};

export const SchemaTypesUtil = {
  format: (schema: ZodMergedType): SchemaDefinition => {
    // @ts-expect-error This is a package issue
    return formatProperty(schema);
  },
  validate: (schema: ZodMergedType, formData: unknown) => {
    const parsedStructure = schema.safeParse(formData) as {
      success: boolean;
      data?: unknown;
      error?: ZodError;
    };
    return {
      values: parsedStructure.data,
      errors: formatErrors(parsedStructure.error?.issues) || null,
    };
  },
};
