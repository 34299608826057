import React from 'react';
import styled from 'styled-components';

import { ExperienceShape } from '../../../models/experience-model';
import { DateUtil } from '../../../utils/date-util';
import { formatLocation } from '../../../utils/location';
import { Gutter } from '../../layout/Gutter/Gutter';
import { RichText } from '../../general/RichText/RichText';
import { Header4 } from '../../content/Header4/Header4';
import { Header5 } from '../../content/Header5/Header5';
import { Text } from '../../content/Text/Text';
import { CertifiedIcon } from '../../icons/CertifiedIcon/CertifiedIcon';

const IconWrapper = styled.span`
  margin-right: 0.25rem;
  position: relative;
  top: -0.1875rem;
`;

export const Experience = ({ experience }) => (
  <div data-test="EXPERIENCE">
    <Header4 name="experienceClientName">
      {!!experience.isAxiom && (
        <IconWrapper>
          <CertifiedIcon />
        </IconWrapper>
      )}
      {experience.calculatedDisplayName}
    </Header4>

    {!experience.isAxiom && (
      <Header5 as="div" name="experienceOpportunityName" subtle>
        {experience.externalOpportunityName}
      </Header5>
    )}

    <Gutter bottom="0.5rem">
      <Text name="experienceDateRange">
        {DateUtil.displayDateRange(experience.startDate, experience.endDate)}
      </Text>
      <Text name="experienceLocation">
        {formatLocation(experience.locationAddressComponents)}
      </Text>
    </Gutter>

    <Gutter right="1.25rem">
      <div data-test="EXPERIENCEDESCRIPTION">
        <RichText
          plainText={experience.submissionDescription || experience.description}
        />
      </div>
    </Gutter>
  </div>
);

Experience.propTypes = {
  experience: ExperienceShape.isRequired,
};

Experience.defaultProps = {};
