import React, { useState } from 'react';
import { string, func, node } from 'prop-types';

import { Button } from '../../element/Button/Button';
import { Modal } from '../../element/Modal/Modal';
import { ModalHeader } from '../../element/Modal/ModalHeader';
import { ModalSection } from '../../element/Modal/ModalSection';
import { ModalFooter } from '../../element/Modal/ModalFooter';

export const ConfirmSubmitDialog = ({
  modalTitle,
  modalBody,
  name,
  onNo,
  onYes,
  Trigger,
  noButtonText,
  yesButtonText,
  size,
}) => {
  const [showModal, setShowModal] = useState(!Trigger);

  const onCancel = () => {
    if (Trigger) {
      setShowModal(false);
    }
    onNo();
  };
  const AttachedTrigger = Trigger
    ? React.cloneElement(Trigger, { onClick: () => setShowModal(true) })
    : null;
  return (
    <>
      {!!showModal && (
        <Modal name={name} size={size}>
          <ModalHeader onClose={onCancel}>{modalTitle}</ModalHeader>
          <ModalSection>{modalBody}</ModalSection>
          <ModalFooter>
            <Button variation="outline" name="CANCEL" onClick={onCancel}>
              {noButtonText}
            </Button>
            <Button name="SUBMIT" onClick={onYes}>
              {yesButtonText}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {AttachedTrigger}
    </>
  );
};

ConfirmSubmitDialog.propTypes = {
  modalBody: string,
  modalTitle: string,
  name: string,
  noButtonText: string,
  size: string,
  Trigger: node,
  yesButtonText: string,
  onNo: func,
  onYes: func.isRequired,
};

ConfirmSubmitDialog.defaultProps = {
  modalTitle: 'Confirm action',
  modalBody: "Are you sure you'd like to proceed",
  name: undefined,
  noButtonText: 'Cancel',
  onNo: () => {},
  Trigger: null,
  yesButtonText: 'Submit',
  size: 'confirm',
};
