import { NavigateFunction, Location } from 'react-router-dom';
import { CookieUtil, DetectBrowserUtil } from '@axiom/ui';
import { isLpUser } from '@axiom/utils';

import { WindowUtil } from './WindowUtil';
import { EnvUtil } from './env-util';

const { getUrlWithRelayState } = WindowUtil;

export const MfaUtil = {
  handleNavigation: (
    navigate: NavigateFunction,
    location: Location,
    jwt?: string
  ) => {
    if (jwt) {
      CookieUtil.setUserCookie(jwt, {
        domain: EnvUtil.cookieDomain,
        isSafari: DetectBrowserUtil.isSafari(),
      });
      const decodedCookie = CookieUtil.decodeUserCookie();

      if (isLpUser(decodedCookie)) {
        navigate(getUrlWithRelayState(`/two-factor-register`, { location }));
      } else {
        navigate(getUrlWithRelayState(`/sso`, { location }));
      }
    } else {
      navigate(getUrlWithRelayState(`/two-factor-confirm`, { location }));
    }
  },
};
