import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const FormIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 26 30"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <path d="M24,30H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H24a2,2,0,0,1,2,2V28A2,2,0,0,1,24,30ZM1.678,1.538V28.462H24.322V1.538Z" />
    <rect width="14.258" height="2.308" x="5.871" y="6.154" />
    <rect width="14.258" height="2.308" x="5.871" y="21.538" />
    <rect width="14.258" height="2.308" x="5.871" y="13.846" />
  </svg>
);

FormIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

FormIcon.defaultProps = {
  alt: null,
  className: null,
  height: 30,
  name: 'FORM_ICON',
  width: 26,
};
