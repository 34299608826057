import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import styled, { ThemeProvider } from 'styled-components';
import {
  Blocker,
  DesignSysEnvUtil,
  ErrorBoundary,
  I18nProvider,
  LightTheme,
  LoggerUtil,
  ServiceWorkerProvider,
  Suspense,
  Toasts,
  ConsumeWarningsUtil,
} from '@axiom/ui';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import GoogleTagManager from './components/GoogleTagManager/GoogleTagManager';
import themes from './themes';
import enLocale from './locales/en/messages';
import deLocale from './locales/de/messages';
import laLocale from './locales/la/messages';
import { EnvUtil } from './utils/env-util';
import { PreloadedFeatureFlagsStore } from './stores/preloaded-feature-flags-store';
import { Router } from './Router';
import { initializeStore } from './lib/store-manager';
import { ErrorBoundaryLayout } from './layouts/ErrorBoundaryLayout/ErrorBoundaryLayout';
import { GoogleAnalytics } from './components/GoogleAnalytics/GoogleAnalytics';
import { PendoInitializer } from './components/PendoInitializer/PendoInitializer';
import { LoginContextProvider } from './components/LoginContext/LoginContextProvider';

import '@progress/kendo-theme-default/dist/all.css';
import '@axiom/ui/src/public/static/kendo-overrides.css';
import '@axiom/ui/src/public/static/bootstrap.min.css';
// eslint-disable-next-line import/no-unresolved
import 'semantic-ui-less/semantic.less';
import '@axiom/ui/src/public/static/main.css';
import '@axiom/ui/src/public/static/main-internal.css';
import '@axiom/ui/src/public/static/css-helpers.css';

const AppWrapper = styled.div``;

DesignSysEnvUtil.initialize({
  COOKIE_DOMAIN: EnvUtil.cookieDomain,
  ENABLE_SUGGESTIONS: EnvUtil.enableSuggestions,
});

if (EnvUtil.enableLogging) {
  LoggerUtil.initialize(
    EnvUtil.logglyClientId,
    'login',
    EnvUtil.environmentName
  );
}

/**
 * Temp disable until we can get to this ticket
 * APCORE-2484
 */
if (process.env.NODE_ENV !== 'production') {
  ConsumeWarningsUtil.consumePropWarnings();
}

const store = initializeStore();
PreloadedFeatureFlagsStore.bootstrap();

const Bootstrap = () => {
  return (
    <AppWrapper>
      <I18nProvider
        locales={{
          en: enLocale,
          de: deLocale,
          la: laLocale,
        }}
      >
        <Provider store={store}>
          <ThemeProvider theme={themes.main}>
            <ErrorBoundary
              errorLayout={<ErrorBoundaryLayout />}
              ignoreUnauthorizedError
            >
              <LightTheme backgroundName="none">
                <Blocker>
                  <Toasts />
                  <Suspense>
                    <PendoInitializer />
                    <BrowserRouter future={{ v7_startTransition: true }}>
                      <ServiceWorkerProvider />
                      <LoginContextProvider>
                        <Router />
                      </LoginContextProvider>
                      <GoogleAnalytics />
                      <GoogleTagManager
                        gtmId={EnvUtil.gtmAccountId}
                        additionalEvents={{ platform: 'react-stack' }}
                      />
                    </BrowserRouter>
                  </Suspense>
                </Blocker>
              </LightTheme>
            </ErrorBoundary>
          </ThemeProvider>
        </Provider>
      </I18nProvider>
    </AppWrapper>
  );
};

const root = createRoot(document.querySelector('#root'));
root.render(<Bootstrap />);
