import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import { FieldInputProps, FormikHelpers } from 'formik/dist/types';

import { IconName } from '../../element/Icon/IconTypes';
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import { Icon } from '../../element/Icon/Icon';
import { useFormContext } from '../Form/useFormContext';
import { CondensedSmall } from '../../content/CondensedSmall/CondensedSmall';
import { CondensedMedium } from '../../content/CondensedMedium/CondensedMedium';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import {
  FormGroupContext,
  FormGroupContextType,
} from '../FormGroup/FormGroupContext';
import { FormError } from '../FormError/FormError';
import { Gutter } from '../../layout/Gutter/Gutter';
import { IconButton } from '../../element/Button/IconButton';

export const FormFieldRoot = styled.div``;

export interface FormFieldProps<FormValue> {
  dataTest?: string;
  description?: string | React.ReactNode;
  disabled?: boolean;
  icon?: IconName;
  id?: string;
  label?: React.ReactNode;
  name: string;
  onClear?: (v: FormValue) => void;
  renderField: (args: {
    value: FormValue;
    onChange: FieldInputProps<FormValue>['onChange'];
    onBlur: FieldInputProps<FormValue>['onBlur'];
    invalid: boolean;
    schemaProperty: SchemaDefinitionProperty;
    setFieldValue: FormikHelpers<FormValue>['setFieldValue'];
    setFieldTouched: FormikHelpers<FormValue>['setFieldTouched'];
  }) => React.ReactNode;
  showCharactersRemaining?: boolean;
  Tooltip?: React.ReactNode;
  AppendButton?: React.ReactNode;
  stretched?: boolean;
}

export const FormField = <FormValue,>({
  dataTest,
  description = null,
  disabled = false,
  icon,
  id = null,
  label = null,
  name,
  onClear,
  renderField,
  showCharactersRemaining = null,
  Tooltip = null,
  AppendButton = null,
}: FormFieldProps<FormValue>) => {
  if (!name) {
    throw new Error('You must provide a name property to form elements');
  }
  const formGroup = useContext(FormGroupContext) as FormGroupContextType;
  const schemaProperty = useFormContext().getSchemaProperty(name);

  useEffect(() => {
    if (formGroup) {
      formGroup.initialize(name);
    }
  }, []);

  const { maxLength, optional } = schemaProperty.constraints;

  return (
    <FormFieldRoot
      className={AttrsHelper.formatClassname(
        'form-field',
        'stretchable',
        disabled && 'disabled'
      )}
      data-test={`${dataTest || name}-form-field`}
    >
      <Field name={name}>
        {({
          field: { value, onBlur, onChange },
          form: { submitCount, setFieldValue, setFieldTouched },
          meta: { touched, error },
        }: FieldProps) => {
          const invalid = !!(
            (touched || submitCount > 0) &&
            typeof error === 'string'
          );

          const displayError = (invalid && error) || null;
          const hasValue = (() => {
            if (Array.isArray(value)) {
              return value.length > 0;
            } else if (typeof value === 'number') {
              return true;
            }
            return !!value;
          })();

          const shouldShowClearButton = onClear && !disabled && hasValue;
          return (
            <>
              <div
                className={AttrsHelper.formatClassname(
                  'element',
                  'stretchable',
                  invalid && 'invalid'
                )}
              >
                <FormFieldLabel
                  name={name}
                  label={label}
                  Tooltip={Tooltip}
                  required={!optional}
                  id={id}
                />
                {!!description && (
                  <CondensedMedium
                    name="form-description"
                    className="description"
                  >
                    {description}
                  </CondensedMedium>
                )}
                <div className="form-element-container stretchable">
                  {renderField({
                    value,
                    onChange,
                    onBlur,
                    invalid,
                    schemaProperty,
                    setFieldValue,
                    setFieldTouched,
                  })}
                  {!shouldShowClearButton && !!icon && (
                    <span
                      data-test="form-element-icon"
                      className="control-icon"
                    >
                      <Icon name={icon} />
                    </span>
                  )}
                  {!!shouldShowClearButton &&
                    (AppendButton || (
                      <IconButton
                        pattern="secondary"
                        variation="minimal"
                        icon="x-mark"
                        name="form-element-clear"
                        className="control-icon control-icon-clear fixed-dimension-inline"
                        tabIndex={-1}
                        onClick={e => {
                          e.stopPropagation();
                          onClear(null);
                          setFieldValue(name, null);
                        }}
                      />
                    ))}
                </div>
              </div>
              {!!showCharactersRemaining && !displayError && !!maxLength && (
                <Gutter top="8px">
                  <CondensedSmall name="form-max-chars-wrapper">
                    <span data-test="form-chars-current">
                      {value?.length || 0}
                    </span>
                    /<span data-test="form-chars-max">{maxLength}</span>{' '}
                    Characters
                  </CondensedSmall>
                </Gutter>
              )}
              {!formGroup && <FormError error={displayError} />}
            </>
          );
        }}
      </Field>
    </FormFieldRoot>
  );
};
