import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const AxiomAnswersIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 109 109"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <circle
      fill="#FFFFFF"
      stroke="#9B9B9B"
      strokeWidth="4"
      cx="54.5"
      cy="54.5"
      r="52.5"
    />
    <g fill="#F15B35" transform="translate(17.75, 31.75)">
      <path d="M33.3624108,23.5718163 C33.2868701,31.2891042 29.3523676,36.1650269 22.5537005,36.225 C15.9731439,36.1672901 12.2163218,31.3241828 12.1333333,23.4665806 C12.2152578,15.8398181 15.9593125,11.0849731 22.5537005,11.025 C29.3544955,11.0827099 33.290062,15.9744746 33.3666667,23.5718163 L33.3624108,23.5718163 Z M44.737424,0.915756542 L44.7480117,0.930149153 L33.2126722,0.930149153 L33.279375,6.28198622 C30.3793938,2.41148097 25.956896,-0.0230845477 20.0976424,-3.10857026e-15 C14.4024987,-0.00204919307 9.33838404,2.50669362 5.7449061,6.70158618 C2.14719307,10.8953716 0,16.7642356 0,23.4789422 C0,30.2523264 2.19907298,36.1687966 5.80419743,40.3957958 C9.40720433,44.6294377 14.4395557,47.1669657 19.9991764,47.1658589 L20.0267046,47.1658589 C25.7853747,47.1658589 30.3815113,44.5940097 33.2783162,40.7843963 L33.2105547,46.2347674 L45.1249351,46.2347674 L45.1249351,46.2115178 L44.737424,0.915756542 Z M54.6,40.2065765 C54.6,44.2583081 57.7775825,47.2389809 61.4255273,47.25 C65.0703083,47.2389809 68.25,44.2583081 68.25,40.2065765 C68.241563,35.9928638 65.0523797,33.0816115 61.4255273,33.075 C57.7955111,33.0816115 54.6063277,35.9928638 54.6,40.2065765 Z" />
      <rect x="54.25" y="0" width="14" height="29.75" />
    </g>
  </svg>
);

AxiomAnswersIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

AxiomAnswersIcon.defaultProps = {
  alt: null,
  className: null,
  height: 109,
  name: 'AXIOM_ANSWERS_ICON',
  width: 109,
};
