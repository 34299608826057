import { SchemaTimestamp } from '@axiom/types';
import { z } from 'zod';
import { AccountConst, CandidateOpportunitiesConst } from '@axiom/const';

import { requireAtLeastOneDefined } from './general';
import { CandidateSchema } from './candidate';
import { UserSchema } from './user';

export const CandidateAccountSchema = CandidateSchema.extend({
  accountCandidateStatus:
    CandidateOpportunitiesConst.ZodCandidateStatuses.nullable(),
  accountId: z.string().uuid(),
  billingUnitsPerWeek: z.string().max(255).nullable(),
  calculatedNotes: z.string().nullable(),
  candidateId: z.string().uuid(),
  hasOtherEngagements: z.boolean().nullable(),
  id: z.string().uuid(),
  isHiddenExternal: z.boolean().nullable(),
  notes: z.string().nullable(),
  notesUpdatedAt: SchemaTimestamp.nullable(),
  notesUpdatedBy: z.string().uuid().nullable(),
  weeklyTimeUnit: z.string().max(255).nullable(),
  notesUpdatedByUser: UserSchema.nullish(),
  accountType: z.literal(AccountConst.Types.Direct).nullable(),
});

export const updateCandidateAccountSchema = CandidateAccountSchema.partial()
  .pick({
    isHiddenExternal: true,
    notes: true,
  })
  .strict()
  .refine(requireAtLeastOneDefined);
