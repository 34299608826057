import Ajv from 'ajv';
import { z } from 'zod';
import { ClientRequestConst, CandidateOpportunitiesConst } from '@axiom/const';

import { axiomValidationOptions } from './options';

const RejectionLossCodesValues = Object.values(
  ClientRequestConst.AfcRejectionLossCodes
) as NonEmptyArray<string>;

const RequestTypesValues = Object.values(
  ClientRequestConst.RequestTypes
) as NonEmptyArray<string>;

export const ClientRequestSchema = z.object({
  id: z.string().uuid(),
  candidateId: z.string().uuid(),
  clientUserId: z.string().uuid(),
  notes: z.string().nullish(),
  opportunityId: z.string().uuid(),
  rejectionLossCodes: z.array(z.enum(RejectionLossCodesValues)).nullable(),
  requestType: z.union([
    z.literal(ClientRequestConst.RequestTypes.InterviewRequested),
    z.literal(ClientRequestConst.RequestTypes.EngageRequested),
    z.literal(ClientRequestConst.RequestTypes.ClientPassed),
  ]),
  submissionId: z.string().uuid(),
  candidateOpportunityStatus:
    CandidateOpportunitiesConst.ZodCandidateStatuses.nullable(),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const createClientRequestValidator = ajv.compile({
  type: 'object',
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    clientUserId: {
      type: 'string',
      format: 'uuid',
    },
    notes: {
      type: 'string',
    },
    rejectionLossCodes: {
      type: 'array',
      items: {
        type: 'string',
        enum: RejectionLossCodesValues,
      },
    },
    requestType: {
      type: ['string', 'null'],
      enum: [...RequestTypesValues, null],
    },
    submissionId: {
      type: 'string',
      format: 'uuid',
    },
  },
  required: ['candidateId', 'submissionId', 'requestType', 'clientUserId'],
});
