import React from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, useApiWithErrors } from '@axiom/ui';

import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout';
import { ExpiredLink } from '../ExpiredLink/ExpiredLink';
import { ClientResetPassword } from '../ClientResetPassword/ClientResetPassword';
import { LegacyAuthApi } from '../../api/legacy-auth-api';

export const SetPassword = () => {
  const { token, providedEmail } = useParams();
  const [response] = useApiWithErrors(
    token && LegacyAuthApi.readTokenIsValid(token)
  );
  const tokenIsValid = !(response instanceof ApiError);

  return tokenIsValid ? (
    <ClientResetPassword token={token} email={providedEmail} />
  ) : (
    <DefaultLayout>
      <ExpiredLink />
    </DefaultLayout>
  );
};
