import React from 'react';
import { bool, number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const InfoIcon = ({
  alt,
  className,
  height,
  hideBorder,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 21 20"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    {!hideBorder && (
      <path d="M10.5.5C4.997.5.5 4.744.5 10s4.497 9.5 10 9.5 10-4.244 10-9.5S16.003.5 10.5.5zm0 1c4.99 0 9 3.815 9 8.5 0 4.685-4.01 8.5-9 8.5s-9-3.815-9-8.5c0-4.685 4.01-8.5 9-8.5z" />
    )}
    <text
      fontFamily="Walsheim, GTWalsheimProMedium, GT Walsheim Pro"
      fontSize="16"
      fontWeight="700"
    >
      <tspan x="8.396" y="16">
        i
      </tspan>
    </text>
  </svg>
);

InfoIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  hideBorder: bool,
  name: string,
  width: number,
};

InfoIcon.defaultProps = {
  alt: null,
  className: null,
  height: 20,
  hideBorder: false,
  name: 'INFO_ICON',
  width: 21,
};
