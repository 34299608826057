import Ajv from 'ajv';
import { z } from 'zod';
import {
  LANGUAGE_PROFICIENCIES_KEYS,
  LANGUAGE_SKILLS_KEYS,
  LanguageConst,
} from '@axiom/const';

import { axiomValidationOptions } from './options';

export const ProficiencyValues = Object.values(
  LANGUAGE_PROFICIENCIES_KEYS
) as NonEmptyArray<string>;

export const SkillsValues = Object.values(
  LANGUAGE_SKILLS_KEYS
) as NonEmptyArray<string>;

export const CEFRValues = Object.values(
  LanguageConst.LANGUAGE_CEFR_LEVELS
) as NonEmptyArray<string>;

// Java version
export const LanguageSchema = z.object({
  id: z.string().uuid(),
  name: z.string().max(255),
  languageCEFR: z.enum(CEFRValues).nullish(),
  languageProficiency: z.enum(ProficiencyValues).nullable(),
  languageSkill: z.enum(SkillsValues).nullable(),
});

const ajv = new Ajv(axiomValidationOptions());

const languageValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
    },
  },
  required: ['name'],
};

export const languageValidator = ajv.compile(languageValidation);
