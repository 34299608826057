import { CandidatesConst } from './candidate';
import { CountryCodesConst } from './country-codes';
import { MLRecommendationsConst } from './ml-recommendations';

const { ProfileStatuses } = CandidatesConst;
const { CountryCodesAbbreviations } = CountryCodesConst;
const { NonConformingCountryCodesAbbreviations } = MLRecommendationsConst;

const series = (length: number, start?: number, step?: number) =>
  Array.from({ length }, (_, i) => (start || 0) + i * (step || 1));

const tcObj = ({
  i,
  addressCountryCode,
  addressState,
  compensation,
  hourlyCompensation,
  desiredWeeklyMaxHours,
  maxHours,
  occupationType,
  profileStatus,
}: {
  i?: number;
  addressCountryCode?: string;
  addressState?: string;
  compensation?: number;
  desiredWeeklyMaxHours?: number;
  hourlyCompensation?: number;
  maxHours?: number;
  occupationType?: string;
  profileStatus?: string;
} = {}) => ({
  id: `abadabad-be00-0000-0000-${String(i).padStart(12, '0')}`,
  firstName: `Extra${i}`,
  lastName: 'MLSuggested',
  profileStatus: profileStatus || ProfileStatuses.Waitlist,
  maxHours: maxHours ?? 50,
  desiredWeeklyMaxHours: desiredWeeklyMaxHours ?? 50,
  addressCountryCode: addressCountryCode || CountryCodesAbbreviations.US,
  addressState: addressState || 'Washington',
  compensation: compensation || 100009.21,
  hourlyCompensation: hourlyCompensation || 102.31,
  occupationType: occupationType || 'Lawyer',
});

export const TestMLRecommendedCandidateData = [
  {
    id: 'abadabad-be00-0000-0000-000000000001',
    firstName: 'Tony1hr',
    lastName: 'MLSuggested',
    profileStatus: ProfileStatuses.Waitlist,
    maxHours: 1,
    desiredWeeklyMaxHours: 1,
    addressCountryCode: CountryCodesAbbreviations.US,
    occupationType: 'Lawyer',
  },
  {
    id: 'abadabad-be00-0000-0000-000000000002',
    firstName: 'Clark5hrs',
    lastName: 'MLSuggested',
    profileStatus: ProfileStatuses.Waitlist,
    maxHours: 5.1,
    desiredWeeklyMaxHours: 5,
    addressCountryCode: CountryCodesAbbreviations.US,
    occupationType: 'Lawyer-Flex',
  },
  {
    id: 'abadabad-be00-0000-0000-000000000003',
    firstName: 'Bruce30hrs',
    lastName: 'MLSuggested',
    profileStatus: ProfileStatuses.Waitlist,
    maxHours: 30,
    desiredWeeklyMaxHours: 30,
    addressCountryCode: CountryCodesAbbreviations.US,
    occupationType: 'Legal Support',
  },
  {
    id: 'abadabad-be00-0000-0000-000000000004',
    firstName: 'Peter',
    lastName: 'MLSuggested',
    profileStatus: ProfileStatuses.Waitlist,
    maxHours: 40,
    desiredWeeklyMaxHours: 40,
    addressCountryCode: NonConformingCountryCodesAbbreviations.GB,
    addressState: 'Northumberland',
    occupationType: 'Lawyer',
  },
  tcObj({
    i: 5,
    profileStatus: ProfileStatuses.Alum,
    maxHours: 40,
    desiredWeeklyMaxHours: 40,
    occupationType: 'Lawyer',
  }),
  tcObj({
    i: 6,
    profileStatus: ProfileStatuses.Beach,
    maxHours: 40,
    desiredWeeklyMaxHours: 40,
    occupationType: 'Legal Support',
  }),
  tcObj({
    i: 7,
    profileStatus: ProfileStatuses.Waitlist,
    maxHours: 40,
    desiredWeeklyMaxHours: 80,
  }),
  tcObj({
    i: 8,
    profileStatus: ProfileStatuses.Certifying,
    maxHours: 40,
    desiredWeeklyMaxHours: 40,
  }),
  tcObj({
    i: 9,
    profileStatus: ProfileStatuses.InDiligence,
    maxHours: 40,
    desiredWeeklyMaxHours: 40,
    occupationType: 'Legal Support',
  }),
  // some US
  ...series(5, 10).map(i =>
    tcObj({
      i,
      addressCountryCode: CountryCodesAbbreviations.US,
      occupationType: 'Lawyer',
    })
  ),
  ...series(5, 15).map(i =>
    tcObj({
      i,
      addressCountryCode: CountryCodesAbbreviations.US,
      occupationType: 'Legal Support',
    })
  ),
  // some UK
  ...series(5, 20).map(i =>
    tcObj({
      i,
      addressCountryCode: CountryCodesAbbreviations.UK,
      addressState: 'Isles of Scilly',
      occupationType: 'Lawyer',
    })
  ),
  ...series(5, 25).map(i =>
    tcObj({
      i,
      addressCountryCode: CountryCodesAbbreviations.UK,
      addressState: 'Isles of Scilly',
      occupationType: 'Legal Support',
    })
  ),
  // some low pay
  ...series(2, 30).map(i =>
    tcObj({
      i,
      compensation: 10000.21,
      hourlyCompensation: 4.8,
    })
  ),
  // some high pay
  ...series(3, 32).map(i =>
    tcObj({
      i,
      compensation: 500321.99,
      hourlyCompensation: 240.54,
    })
  ),
  // some Californians
  ...series(5, 35).map(i =>
    tcObj({
      i,
      addressState: 'California',
    })
  ),
];
