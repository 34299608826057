import Ajv from 'ajv';
import { SchemaBadges } from '@axiom/types';

import { CandidateSchema } from './candidate';
import { axiomValidationOptions } from './options';

export const ExternalCandidateSchema = CandidateSchema.pick({
  id: true,
  addressCity: true,
  addressCountry: true,
  addressState: true,
  candidateHeader: true,
  occupationType: true,
  practiceArea: true,
  profileImageKey: true,
  profileImageName: true,
  publicCandidateSummary: true,
  calculatedDisplayName: true,
  yearsOfExperience: true,
  isSpotlightedLawyer: true,
  hasRate: true,
  barredLocations: true,
  publicRefId: true,
  industries: true,
  weeklyAvailability: true,
  lawDegreeSchools: true,
  lawFirms: true,
  isViewOnly: true,
  isAvailableToConsult: true,
}).extend({
  badges: SchemaBadges,
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const idOrPublicRefIdValidation = {
  anyOf: [
    {
      type: 'string',
      format: 'uuid',
    },
    {
      type: 'number',
      maximum: Number.MAX_SAFE_INTEGER,
    },
  ],
};

export const idOrPublicRefIdValidator = ajv.compile(idOrPublicRefIdValidation);
