import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const CertifiedIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 15 15"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <g transform="translate(1.777778, 1.777778)">
      <path
        d="M12.4444444,6.22209975 C12.4444444,9.65854661 9.65857584,12.4444444 6.22222222,12.4444444 C2.7858686,12.4444444 0,9.65854661 0,6.22209975 C0,2.7856529 2.7858686,0 6.22222222,0 C9.65857584,0 12.4444444,2.7856529 12.4444444,6.22209975 Z"
        fill="#F15B35"
        stroke="#F15B35"
      />
      <polyline
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="2"
        points="2.33333333 6.16760004 4.65797441 8.80116959 9.24691358 3.88888889"
      />
    </g>
  </svg>
);

CertifiedIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

CertifiedIcon.defaultProps = {
  alt: null,
  className: null,
  height: 15,
  name: 'CERTIFIED_ICON',
  width: 15,
};
