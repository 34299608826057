import React from 'react';
import { oneOf, node, string, bool } from 'prop-types';
import styled from 'styled-components';

import { DataTestUtil } from '../../../utils/data-test-util';

const H5 = ({ as, children, dataTest }) =>
  React.createElement(as, { dataTest }, children);

H5.propTypes = {
  as: string.isRequired,
  children: node.isRequired,
  dataTest: string.isRequired,
};

export const Header5SketchStyles = ({ theme, accent, subtle }) => ({
  fontSize: '1rem', // * 16px = 16px
  fontWeight: 'bold',
  lineHeight: '1.625rem', // * 16px = 26px
  fontFamily: accent ? 'Caslon' : 'Walsheim',
  color: subtle ? theme.text.subtle : theme.text.default,
});

const StyledH5 = styled(H5)`
  ${props => Header5SketchStyles(props)}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase'};
  margin: 0;
`;

export const Header5 = ({
  as,
  accent,
  children,
  className,
  name,
  subtle,
  uppercase,
}) => (
  <StyledH5
    as={as}
    accent={accent}
    subtle={subtle}
    uppercase={uppercase}
    className={className}
    data-test={DataTestUtil.format(name || as)}
  >
    {children}
  </StyledH5>
);

Header5.propTypes = {
  accent: bool,
  as: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span']),
  children: node,
  className: string,
  name: string,
  subtle: bool,
  uppercase: bool,
};

Header5.defaultProps = {
  as: 'h5',
  children: null,
  className: '',
  name: null,
  accent: false,
  subtle: false,
  uppercase: false,
};
