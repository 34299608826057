import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const BulletsIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 38 38"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <g transform="translate(11.25, 12)">
      <ellipse cx="1.357" cy="1.425" rx="1.357" ry="1.425" />
      <rect x="4.745" y="0.7125" width="9.5" height="1.425" />
    </g>
    <g transform="translate(11.25, 18.615)">
      <ellipse cx="1.357" cy="1.425" rx="1.357" ry="1.425" />
      <rect x="4.745" y="0.7125" width="9.5" height="1.425" />
    </g>
    <g transform="translate(11.25, 24.825)">
      <ellipse cx="1.357" cy="1.425" rx="1.357" ry="1.425" />
      <rect x="4.745" y="0.7125" width="9.5" height="1.425" />
    </g>
  </svg>
);

BulletsIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

BulletsIcon.defaultProps = {
  alt: null,
  className: null,
  height: 41,
  name: 'BULLETS_ICON',
  width: 41,
};
