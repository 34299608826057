import { createContext } from 'react';

export type LoginContextType = {
  loginState: {
    email: string;
    password: string;
    countryCode: string;
    phoneNumber: string;
  };
  setLoginState: () => void;
  isInvalidMfaState: boolean;
};

export const InitialLoginState: LoginContextType['loginState'] = {
  email: null,
  password: null,
  countryCode: null,
  phoneNumber: null,
};

export const LoginContext = createContext(null);
