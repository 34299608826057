import memoize from 'lodash/memoize';

import { request } from '../utils/hubspot-request-util';

const HUBSPOT_FORM_API_NAME = 'Hubspot Form API';

export const postValidateEmail = async email =>
  request(
    HUBSPOT_FORM_API_NAME,
    'https://forms.hsforms.com/emailcheck/v1/json-ext?portalId=1687417',
    'POST',
    email
  );

export const memoizedPostValidateEmail = memoize(postValidateEmail);
