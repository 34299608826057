import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaDate, SchemaTimestamp } from '@axiom/types';
import {
  Opportunity,
  TimeCommitment,
  PositionsConst,
  EngagementLengthConst,
  CountryCodesConst,
  CandidateOpportunitiesConst,
  WorkFeedConst,
} from '@axiom/const';

import { axiomValidationOptions } from './options';
import { AccountSchema } from './account';
import { OpportunitySchema } from './opportunity';
import { PositionSchema } from './positions';
import { PracticeAreaSchema } from './practice-area';

const { Phases } = WorkFeedConst;
const { CalculatedTalentTypes, Worksites } = PositionsConst;
const { EngagementLengths } = EngagementLengthConst;

const PhasesValues = Object.values(Phases) as NonEmptyArray<string>;

const EngagementLengthValues = Object.values(
  EngagementLengths
) as NonEmptyArray<string>;

const CalculatedTalentTypeValues = Object.values(
  CalculatedTalentTypes
) as NonEmptyArray<string>;

const TimeCommitmentValues = Object.values(
  TimeCommitment.HoursPerWeek
) as NonEmptyArray<string>;

const WorksitesValues = Object.values(Worksites) as NonEmptyArray<string>;

const CountryCodeKeys = Object.keys(
  CountryCodesConst.CountryCodesAbbreviations
) as NonEmptyArray<string>;

export const CandidateWorkFeedItemSchema = z.object({
  account: AccountSchema.partial().pick({
    calculatedName: true,
    website: true,
  }),
  addedToPoolAt: SchemaTimestamp.nullable(),
  candidateIsInterested: z.boolean().default(false),
  candidateStatus: CandidateOpportunitiesConst.ZodCandidateStatuses.nullable(),
  id: z.string().uuid(),
  isNotAvailableOnCandidateFeed: z.boolean().default(false),
  isSaved: z.boolean().default(false),
  opportunity: OpportunitySchema.partial()
    .pick({
      allowInterest: true,
      accountName: true,
      closedDate: true,
      description: true,
      id: true,
      isExcludedFromFeed: true,
      jobName: true,
      languages: true,
      madeAvailableToFeedAt: true,
      madeAvailableToFeedNotificationAt: true,
      locationCityStateCountry: true,
      practiceAreaId: true,
      stage: true,
      directEligible: true,
    })
    .extend({ countryCode: z.enum(CountryCodeKeys).nullable() }),
  phase: z.enum(PhasesValues),
  phaseInterviewingDate: SchemaDate.nullable(),
  phaseSubmittedDate: SchemaDate.nullable(),
  position: PositionSchema.partial().pick({
    billingHoursPerWeek: true,
    billingUnitsPerWeek: true,
    calculatedTalentType: true,
    description: true,
    endDate: true,
    estimatedEngagementTotalMonths: true,
    id: true,
    name: true,
    role: true,
    startDate: true,
    weeklyTimeUnit: true,
    worksite: true,
  }),
  practiceArea: PracticeAreaSchema.partial().pick({ name: true }).nullish(),
  rejectionLossCode:
    CandidateOpportunitiesConst.ZodCandidateRejectionLossCodes.nullable(),
  rejectionLossCodeCustomMessage: z.string().nullable(),
  runningCandidateInterestedCount: z.number().nonnegative().int().default(0),
  runningCandidateInterviewingCount: z.number().nonnegative().int().default(0),
  runningCandidateSubmittedCount: z.number().nonnegative().int().default(0),
});

export const CandidateWorkFeedFormFilterSchema = z.object({
  countries: z.array(z.enum(CountryCodeKeys)).nullish(),
  engagementLength: z.array(z.enum(EngagementLengthValues)).nullish(),
  industries: z.array(z.string()).nullish(),
  opportunityPhases: z.array(z.enum(PhasesValues)).nullish(),
  practiceAreaId: z.array(z.string()).nullish(),
  talentTypes: z.array(z.enum(CalculatedTalentTypeValues)).nullish(),
  timeCommitment: z.array(z.enum(TimeCommitmentValues)).nullish(),
  worksites: z.array(z.enum(WorksitesValues)).nullish(),
});

export const CandidateWorkFeedFilterSchema = z.object({
  filters: CandidateWorkFeedFormFilterSchema,
  page: z.number().nonnegative().int().default(1),
  pageSize: z.number().nonnegative().int().default(10),
});

export const CandidateWorkFeedSearchSchema = z.object({
  meta: z.object({
    currentPage: z.number().nonnegative().int(),
    pageCount: z.number().nonnegative().int(),
    resultCount: z.number().nonnegative().int(),
    aggregations: z.number().nonnegative().int(),
  }),
  data: z.array(CandidateWorkFeedItemSchema).nullish(),
});

export const CandidateWorkFeedInterestedCountSchema = z.object({
  interestedCount: z.number().nonnegative().int(),
});

export const CandidateWorkFeedStatisticsSchema = z.object({
  candidateId: z.string().uuid(),
  roundedTotal: z.number().nonnegative().int(),
});

export const CandidateWorkFeedHideSchema = z.object({
  opportunityId: z.string().uuid(),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const ajvNoCoerce = new Ajv({
  ...axiomValidationOptions(),
});

// Validations
const candidateWorkFeedPageSizeValidation = {
  type: 'number',
  maximum: 100,
  minimum: 1,
  name: 'pageSize',
};

const candidateWorkFeedFilterValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    practiceAreaId: {
      type: 'array',
      items: { type: 'string', format: 'uuid' },
    },
    industries: {
      type: 'array',
      items: { type: 'string', format: 'uuid' },
    },
    timeCommitment: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(TimeCommitment),
      },
    },
    worksites: {
      type: 'array',
      items: { type: 'string', enum: Object.values(PositionsConst.Worksites) },
    },
    engagementLength: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(EngagementLengthConst),
      },
    },
    countries: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.keys(CountryCodeKeys),
      },
    },
    talentTypes: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(PositionsConst.CalculatedTalentTypes),
      },
    },
  },
};

// Validators
export const candidateWorkFeedPageSizeValidator = ajvNoCoerce.compile(
  candidateWorkFeedPageSizeValidation
);

export const candidateWorkFeedFilterValidator = ajv.compile({
  ...candidateWorkFeedFilterValidation,
});

export const candidateWorkFeedSortTermValidator = ajvNoCoerce.compile({
  type: 'object',
  additionalProperties: false,
  properties: {
    sort: {
      type: 'string',
      enum: [`opportunity.${Opportunity.CREATED_AT}`],
    },
  },
});
