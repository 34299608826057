import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const NoSubmissionsIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 150 180"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <path
      fill="#FEFEFE"
      d="M119.6 177H17.4c-8 0-14.4-6.5-14.4-14.4V18.4C3 10.4 9.5 4 17.4 4h102.2c8 0 14.4 6.5 14.4 14.4v144.2c0 8-6.5 14.4-14.4 14.4"
    />
    <mask id="b">
      <path fill="#fff" d="M0 .6h135.8V179H0z" />
    </mask>
    <path
      fill="#A3ABB6"
      d="M17 6C10.8 6 5.6 11.4 5.6 17.8V162c0 6.4 5.2 11.6 11.6 11.6h101.6c6.5 0 11.7-5.2 11.7-11.6V17.7c0-6.4-5.2-11.6-11.7-11.6H17.1zm101.7 173H17.1C7.7 179 0 171.3 0 161.9V17.7C0 8.3 7.7.7 17 .7h101.7c9.5 0 17.1 7.6 17.1 17V162c0 9.4-7.6 17.1-17 17.1z"
      mask="url(#b)"
    />
    <path
      fill="#DADFE1"
      d="M72 69a2 2 0 00-2-2H24c-1.1 0-2 .9-2 2s.9 2 2 2h46c1.1 0 2-.9 2-2zM24.4 62h60.2c1.3 0 2.4-1.1 2.4-2.5S85.9 57 84.6 57H24.4a2.5 2.5 0 00-2.4 2.5c0 1.4 1 2.5 2.4 2.5M24.4 50h60.2c1.3 0 2.4-1.1 2.4-2.5S85.9 45 84.6 45H24.4a2.5 2.5 0 00-2.4 2.5c0 1.4 1 2.5 2.4 2.5M23.5 41h78c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5h-78a2.5 2.5 0 00-2.5 2.5c0 1.4 1 2.5 2.5 2.5M24 31h64c1.1 0 2-.9 2-2a2 2 0 00-2-2H24c-1.1 0-2 .9-2 2s.9 2 2 2M72 131a2 2 0 00-2-2H24c-1.1 0-2 .9-2 2s.9 2 2 2h46c1.1 0 2-.9 2-2zM24.4 124h60.2c1.3 0 2.4-1.1 2.4-2.5s-1.1-2.5-2.4-2.5H24.4a2.5 2.5 0 00-2.4 2.5c0 1.4 1 2.5 2.4 2.5M24.4 112h60.2c1.3 0 2.4-1.1 2.4-2.5s-1.1-2.5-2.4-2.5H24.4a2.5 2.5 0 00-2.4 2.5c0 1.4 1 2.5 2.4 2.5M23.5 103h78c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5h-78a2.5 2.5 0 00-2.5 2.5c0 1.4 1 2.5 2.5 2.5M24 94h64c1.1 0 2-.9 2-2a2 2 0 00-2-2H24c-1.1 0-2 .9-2 2s.9 2 2 2"
    />
    <path
      fill="#5B6471"
      d="M147.3 117c-.8 0-1.5-.3-2-1l-35.6-40.4c-1-1.2-1-2.9.2-4 1.2-1 2.9-.8 3.9.3l35.5 40.5c1 1.2 1 2.9-.2 4-.5.4-1.2.6-1.8.6"
    />
    <path fill="#FEFEFE" d="M119 52a29 29 0 11-58 0 29 29 0 0158 0" />
    <path
      fill="#5B6471"
      d="M90 25.5a26.5 26.5 0 100 53 26.5 26.5 0 000-53M90 84a32 32 0 110-64 32 32 0 010 64"
    />
    <path
      fill="#F05B34"
      d="M101.5 40.5c-.7-.7-1.8-.7-2.4 0l-9.1 9-9-9a1.7 1.7 0 00-2.5 2.4l9 9.1-9 9a1.7 1.7 0 002.4 2.5l9.1-9 9 9a1.7 1.7 0 002.5-2.4l-9-9.1 9-9c.7-.7.7-1.8 0-2.5"
    />
  </svg>
);

NoSubmissionsIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

NoSubmissionsIcon.defaultProps = {
  alt: null,
  className: null,
  height: 150,
  name: 'NO_SUBMISSIONS_ICON',
  width: 180,
};
