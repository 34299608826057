import React from 'react';
import { bool, number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const FlagIcon = ({ alt, className, filled, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 12 20"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <path
      d="M 0.75,0.75 V 18.403 L 5.52,14.442 6,14.044 l 0.48,0.398 4.77,3.96 V 0.75 Z"
      stroke={filled ? '#AD4024' : '#757575'}
      strokeWidth="1.5"
      fill={filled ? '#F05B34' : 'none'}
    />
  </svg>
);

FlagIcon.propTypes = {
  alt: string,
  className: string,
  filled: bool,
  height: number,
  name: string,
  width: number,
};

FlagIcon.defaultProps = {
  alt: null,
  className: null,
  filled: false,
  height: 20,
  name: 'FLAG_ICON',
  width: 12,
};
