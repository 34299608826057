import React from 'react';

import CommonCard from '../../components/CommonCard/CommonCard';

import { DivDefaultLayoutWrapper, DivBodyWrapper } from './DefaultLayoutStyles';

export const DefaultLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <DivDefaultLayoutWrapper>
      <DivBodyWrapper className="hide-for-ie">{children}</DivBodyWrapper>
      <DivBodyWrapper className="show-for-ie">
        <CommonCard className="ie-warning" title="Unsupported Browser">
          <p>This app works best with the following supported browsers:</p>
          <ul>
            <li>Chrome</li>
            <li>Firefox</li>
            <li>Edge</li>
          </ul>
          <p>
            We recommend that you download and use the latest version of a
            supported browser before continuing.
          </p>
        </CommonCard>
      </DivBodyWrapper>
    </DivDefaultLayoutWrapper>
  );
};
