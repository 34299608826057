import { ApiError } from '../classes/api-error';
import { FetchWatcher } from '../classes/endpoint-cache';

import { UnwrapFetchWatcherArray, useApiWithErrors } from './useApiWithErrors';

export const useApi = <
  T extends ReadonlyArray<FetchWatcher<unknown> | undefined | null>,
>(
  ...watchers: T
): UnwrapFetchWatcherArray<T> => {
  const readyData = useApiWithErrors(...watchers);

  readyData.forEach(data => {
    if (data instanceof ApiError) {
      throw data;
    }
  });

  return readyData;
};
