import { bool, shape, string } from 'prop-types';

import { AjvUtil } from '../utils/ajv-util';
import { signupApi } from '../api/signup-api';
import { memoizedPostValidateEmail } from '../api/hubspot-api';
import GenericStore from '../classes/generic-store';

const FormSignUpValidation = AjvUtil.compileSchema({
  required: ['companyName', 'email', 'firstName', 'lastName', 'legalAccepted'],
  properties: {
    companyName: {
      title: 'Company',
      type: 'string',
      isNotEmpty: true,
      maxLength: 255,
    },
    firstName: {
      title: 'First Name',
      type: 'string',
      isNotEmpty: true,
      maxLength: 255,
    },
    lastName: {
      title: 'Last Name',
      type: 'string',
      isNotEmpty: true,
      maxLength: 255,
    },
    legalAccepted: {
      title: 'Legal Disclaimer',
      type: 'boolean',
      const: true,
    },
  },
});

class FormSignUpStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      companyName: string,
      email: string,
      firstName: string,
      lastName: string,
      legalAccepted: bool,
    });
  }

  load() {
    return this.setState({
      companyName: null,
      email: null,
      firstName: null,
      lastName: null,
      legalAccepted: false,
    });
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(FormSignUpValidation, data);
  }

  async asyncValidate(data) {
    return AjvUtil.asyncFormatValidationForFinalForm(
      FormSignUpValidation,
      data
    );
  }

  async validateEmail(email) {
    // regext pulled from ajv-errors
    const emailReg =
      /^[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*@(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?$/i;

    if (!email) {
      return 'Required';
    }

    if (!emailReg.test(email)) {
      return 'Required format: email@example.com';
    }

    // input already does not allow a certain amount of characters
    if (email.length > 255) {
      return 'Field must not be greater than 255 characters';
    }

    const response = await memoizedPostValidateEmail(email);

    if (response.emailFree) {
      return 'Please enter a valid business email';
    }
  }

  submit(formData) {
    const submitValues = {
      ...formData,
      termsAndConditionsAcceptedAt: new Date(),
      legalAccepted: undefined,
    };

    return this.clearState(signupApi(submitValues));
  }
}

export const FormSignUpStore = new FormSignUpStoreClass();
