import get from 'lodash/get';
import { SchemaTypesUtil } from '@axiom/types';

export const FormSchemaUtil = {
  findNode: (schema: SchemaDefinition, propPath: string) => {
    const path = propPath
      .split('.')
      .map((part, idx) => {
        const prependJoin = idx > 0 ? '.' : '';
        if (!Number.isNaN(+part)) {
          return `${prependJoin}arrayType`;
        }
        return `${prependJoin}children.${part}`;
      })
      .join('');
    const prop = get(schema, path);
    if (!prop) {
      throw new Error(`Unknown property: ${propPath}`);
    }
    return prop;
  },
  getRoot: (schema: ZodMergedType) => {
    return schema._def as { typeName: string };
  },
  isObjectSchema: (schema: ZodMergedType) => {
    return FormSchemaUtil.getRoot(schema).typeName === 'ZodObject';
  },
  isArraySchema: (schema: ZodMergedType) => {
    return FormSchemaUtil.getRoot(schema).typeName === 'ZodArray';
  },
  validate: (schema: ZodMergedType, formData: unknown) => {
    return SchemaTypesUtil.validate(schema, formData);
  },
};
