import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const NoteIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 106 93"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <g>
      <rect
        stroke="#BEBEBE"
        strokeWidth="4"
        fill="#BEBEBE"
        x="2"
        y="2"
        width="71"
        height="89"
        rx="5"
      />
      <rect fill="#FFFFFF" x="11" y="14" width="51" height="5" rx="2.5" />
      <rect fill="#FFFFFF" x="11" y="29" width="22" height="5" rx="2.5" />
      <rect fill="#FFFFFF" x="11" y="44" width="51" height="5" rx="2.5" />
      <rect fill="#FFFFFF" x="11" y="74" width="33" height="5" rx="2.5" />
      <rect fill="#FFFFFF" x="11" y="59" width="34" height="5" rx="2.5" />
    </g>
    <g
      transform="translate(42, 11)"
      fill="#D6D6D6"
      stroke="#FFFFFF"
      strokeWidth="2"
    >
      <polygon points="16.2619756 67.1426274 0.868516683 68 0 52.8040574 38.7380244 10 55 24.33857" />
      <path d="M61.8080017,16.0942894 C63.2190625,14.480434 63.4038865,12.2704699 62.2203016,11.1909314 L50.6261452,0.595461851 C49.4390059,-0.48771137 47.3135291,-0.0515342337 45.9024683,1.56595598 L39,9.47166657 L54.9019791,24 L61.8080017,16.0942894 Z" />
    </g>
  </svg>
);

NoteIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

NoteIcon.defaultProps = {
  alt: null,
  className: null,
  height: 93,
  name: 'NOTE_ICON',
  width: 106,
};
