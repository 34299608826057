import React, { useMemo, useState } from 'react';
import { SchemaEmail } from '@axiom/types';

import {
  LoginContext,
  LoginContextType,
  InitialLoginState,
} from './LoginContext';

export type LoginContextProviderProps = {
  children: React.ReactNode;
};

export const isInvalidMfaState = (
  loginState: LoginContextType['loginState']
) => {
  return (
    !loginState.email ||
    !SchemaEmail.safeParse(loginState.email).success ||
    !loginState.password
  );
};

export const LoginContextProvider = ({
  children,
}: LoginContextProviderProps) => {
  const [loginState, setLoginState] = useState(InitialLoginState);
  const providerValue = useMemo(
    () => ({
      loginState,
      setLoginState,
      isInvalidMfaState: isInvalidMfaState(loginState),
    }),
    [loginState]
  );

  return (
    <LoginContext.Provider value={providerValue}>
      {children}
    </LoginContext.Provider>
  );
};
