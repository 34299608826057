/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string, node } from 'prop-types';

import { CardLayoutHeader } from './CardLayoutHeader';
import { CardLayoutBody } from './CardLayoutBody';
import { RSCardWrapper } from './CardLayoutStyles';

export const CardLayout = ({ header, body, footer, ...rest }) => (
  <RSCardWrapper {...rest}>
    <CardLayoutHeader header={header} />
    <CardLayoutBody body={body} />
  </RSCardWrapper>
);

CardLayout.defaultProps = {
  className: 'panel',
  header: null,
  body: null,
  footer: null,
};
CardLayout.propTypes = {
  body: node,
  className: string,
  footer: node,
  header: node,
};
