import React from 'react';
import { arrayOf, bool, node, oneOf, shape, string } from 'prop-types';

import { AxiomLogo } from '../../element/AxiomLogo/AxiomLogo';
import { BarredLocations } from '../BarredLocations/BarredLocations';
import { BarredLocationShape } from '../../../models/barred-location-model';
import { CandidateRateUtil } from '../../../utils/candidate-rate-util';
import { CardLayout } from '../../general/CardLayout/CardLayout';
import { CardMessage } from '../../general/CardMessage/CardMessage';
import { Certification } from '../Certification/Certification';
import { CertificationShape } from '../../../models/certification-model';
import { Education } from '../Education/Education';
import { EducationShape } from '../../../models/education-model';
import { ExperienceShape } from '../../../models/experience-model';
import { Grid } from '../../layout/Grid/Grid';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { GridRow } from '../../layout/Grid/GridRow';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Header2 } from '../../content/Header2/Header2';
import { Header3 } from '../../content/Header3/Header3';
import { Languages } from '../Languages/Languages';
import { LanguageShape } from '../../../models/language-model';
import { RichText } from '../../general/RichText/RichText';

import DSCandidateSkills from './DSCandidateSkills';
import { DSCandidateExperiences } from './DSCandidateExperiences';
import {
  DSCandidateWrapper,
  DSCandidateRecommendedWrapper,
  DSCandidateSection,
} from './DSCandidateStyles';

const CandidateOpportunitiesConst = {
  DisplayBillingRate: {
    hourly: 'hourly',
    daily: 'daily',
    weekly: 'weekly',
    none: 'none',
  },
};

export const DSCandidate = ({
  anonymous,
  candidate,
  experiences,
  currency,
  headerActionButtons,
  mode,
  pageBreak,
}) => {
  const displayName = anonymous
    ? 'Axiom Talent'
    : candidate.calculatedDisplayName;
  const {
    id,
    submissionDescription,
    degrees,
    certifications,
    languages,
    barredLocations,
  } = candidate;

  return (
    <DSCandidateWrapper name={id}>
      <Gutter bottom="80px" gutterType="margin" name="DSCandidate">
        <CardLayout
          header={
            <Grid className={pageBreak ? 'pdf-page-break' : null}>
              <GridRow>
                <GridColumn
                  largeScreenWidth={8}
                  tabletWidth={9}
                  mobileWidth={12}
                  verticalAlign="middle"
                >
                  <Header2 className="ds-candidate-name" accent name="name">
                    {displayName}
                  </Header2>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={4}
                  tabletWidth={3}
                  mobileWidth={12}
                  verticalAlign="middle"
                >
                  <div className="hide-print">{headerActionButtons}</div>
                  <AxiomLogo
                    className="ds-candidate-logo show-print"
                    height="45px"
                    width="200px"
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          }
          body={
            <>
              {!!submissionDescription && (
                <DSCandidateRecommendedWrapper data-test="RECOMMENDED_CONTAINER">
                  <CardMessage>
                    <Header3>Recommendation Details</Header3>
                    <RichText plainText={submissionDescription} />
                  </CardMessage>
                </DSCandidateRecommendedWrapper>
              )}

              <DSCandidateExperiences
                anonymous={anonymous}
                experiences={experiences}
              />

              {(degrees.length > 0 || certifications.length > 0) && (
                <DSCandidateSection
                  borderless={experiences.length === 0}
                  className="pdf-no-break"
                >
                  <Grid stackable>
                    <GridRow columns={2}>
                      {certifications.length > 0 && (
                        <GridColumn>
                          <div data-test="QUALIFICATIONS_CERTIFICATIONS_AWARDS_CONTAINER">
                            <Header3>
                              Qualifications, Certifications, &amp; Awards
                            </Header3>
                            {certifications.map(cert => (
                              <Gutter bottom="0.5rem" key={cert.id}>
                                <Certification certification={cert} />
                              </Gutter>
                            ))}
                          </div>
                        </GridColumn>
                      )}
                      {degrees.length > 0 && (
                        <GridColumn>
                          <div data-test="EDUCATION_CONTAINER">
                            <Header3>Education</Header3>
                            {degrees.map(educationItem => (
                              <Gutter bottom="0.5rem" key={educationItem.id}>
                                <Education education={educationItem} />
                              </Gutter>
                            ))}
                          </div>
                        </GridColumn>
                      )}
                    </GridRow>
                  </Grid>
                </DSCandidateSection>
              )}

              {(languages.length > 0 || barredLocations.length > 0) && (
                <DSCandidateSection className="pdf-no-break">
                  <Grid stackable>
                    <GridRow columns={2}>
                      {languages.length > 0 && (
                        <GridColumn>
                          <div data-test="LANGUAGES_CONTAINER">
                            <Header3>Languages</Header3>
                            <Languages languages={languages} />
                          </div>
                        </GridColumn>
                      )}

                      {barredLocations.length > 0 && (
                        <GridColumn>
                          <div data-test="ADMISSIONS_CONTAINER">
                            <Header3>Admissions</Header3>
                            <Gutter bottom="0.5rem">
                              <BarredLocations
                                barredLocations={barredLocations}
                              />
                            </Gutter>
                          </div>
                        </GridColumn>
                      )}
                    </GridRow>
                  </Grid>
                </DSCandidateSection>
              )}

              {mode === DSCandidate.modes.submission &&
                !!candidate.displayBillingRate &&
                candidate.displayBillingRate !==
                  CandidateOpportunitiesConst.DisplayBillingRate.none && (
                  <DSCandidateSection
                    className="pdf-no-break"
                    data-test="RATE_CONTAINER"
                  >
                    <Header3>Rate</Header3>
                    <div data-test="RATE">
                      {CandidateRateUtil.formatCandidateRateLegacy(
                        candidate,
                        currency
                      )}
                    </div>
                  </DSCandidateSection>
                )}

              {mode === DSCandidate.modes.submission && (
                <DSCandidateSkills experiences={experiences} />
              )}
            </>
          }
        />
      </Gutter>
    </DSCandidateWrapper>
  );
};

DSCandidate.modes = {
  bio: 'bio',
  submission: 'submission',
};

DSCandidate.defaultProps = {
  anonymous: false,
  currency: null,
  headerActionButtons: null,
  mode: DSCandidate.modes.submission,
  pageBreak: false,
  experiences: [],
};

DSCandidate.propTypes = {
  anonymous: bool,
  candidate: shape({
    barredLocations: arrayOf(BarredLocationShape),
    calculatedDisplayName: string.isRequired,
    displayBillingRate: oneOf(
      Object.values(CandidateOpportunitiesConst.DisplayBillingRate)
    ),
    id: string.isRequired,
    submissionDescription: string,
    certifications: arrayOf(CertificationShape),
    degrees: arrayOf(EducationShape),
    languages: arrayOf(LanguageShape),
  }).isRequired,
  currency: string,
  experiences: arrayOf(ExperienceShape),
  headerActionButtons: node,
  mode: oneOf(Object.values(DSCandidate.modes)),
  pageBreak: bool,
};
