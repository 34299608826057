import { z } from 'zod';
import { CountryCodeValues } from '@axiom/const';

export const MfaPhoneNumberSchema = z.object({
  countryCode: z.enum(CountryCodeValues).nullable(),
  phoneNumber: z.string().regex(/^\d+$/).nullable(),
});

export const MfaVerificationSchema = z.object({
  mfaCode: z
    .string()
    .trim()
    .regex(/^\d{6}$/, 'Invalid Code Format'),
});
