import React from 'react';
import { number, oneOf, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

const PATH_DATA = {
  down: 'M0 3.75h15l-7.5 7.5z',
  left: 'M11.25 0v15l-7.5-7.5z',
  right: 'M3.75 15V0l7.5 7.5z',
  up: 'M0 11.25h15l-7.5-7.5z',
};

export const CaretIcon = ({
  alt,
  className,
  direction,
  height,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 15 15"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <path d={PATH_DATA[direction]} />
  </svg>
);

CaretIcon.propTypes = {
  alt: string,
  className: string,
  direction: oneOf(Object.keys(PATH_DATA)),
  height: number,
  name: string,
  width: number,
};

CaretIcon.defaultProps = {
  alt: null,
  className: null,
  direction: 'down',
  height: 15,
  name: 'CARET_ICON',
  width: 15,
};
