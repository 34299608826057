import styled from 'styled-components';

export const PDFFooter = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 24px;
  width: 90%;
  display: flex;
  justify-content: space-between;
`;
