/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { bool, func, shape } from 'prop-types';

import {
  CheckboxWrapper,
  CheckboxIconWrapper,
} from './CheckboxWithLabelRawStyles';

class CheckboxWithLabelRaw extends React.PureComponent {
  onClick = () => {
    if (this.input.current) this.input.current.click();
  };

  input = React.createRef();

  render() {
    const { checked, onValueChange, inputProps, ...props } = this.props;

    return (
      <CheckboxWrapper
        onClick={this.onClick}
        disabled={inputProps.disabled}
        {...props}
      >
        <input
          className="sr-only"
          type="checkbox"
          checked={checked}
          onChange={onValueChange}
          {...inputProps}
          ref={this.input}
        />
        {!!checked && <CheckboxIconWrapper disabled={inputProps.disabled} />}
      </CheckboxWrapper>
    );
  }
}

CheckboxWithLabelRaw.defaultProps = {
  checked: false,
  onValueChange: () => {},
  inputProps: {
    disabled: false,
  },
};

CheckboxWithLabelRaw.propTypes = {
  checked: bool,
  inputProps: shape({
    disabled: bool,
  }),
  onValueChange: func,
};

export { CheckboxWithLabelRaw };
