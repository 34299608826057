type EnvProps = {
  COOKIE_DOMAIN: string;
  GOOGLE_MAPS_API_URL?: string;
  CLIENT_API_BASE?: string;
  S3_PUBLIC_BASE_URL?: string;
  S3_PUBLIC_FILE_BUCKET?: string;
  ENABLE_SUGGESTIONS?: string;
  TYPING_DEBOUNCE?: string;
  ENABLE_CALENDAR_EVENTS_TODAY?: string;
};

const env: EnvProps = {
  COOKIE_DOMAIN: null,
  GOOGLE_MAPS_API_URL: null,
  CLIENT_API_BASE: null,
  S3_PUBLIC_BASE_URL: null,
  S3_PUBLIC_FILE_BUCKET: null,
  ENABLE_SUGGESTIONS: null,
  TYPING_DEBOUNCE: null,
  ENABLE_CALENDAR_EVENTS_TODAY: null,
};

export const DesignSysEnvUtil = {
  initialize(data: EnvProps) {
    Object.keys(env).forEach(key => {
      const value = data[key as keyof EnvProps];
      if (value !== null || value !== undefined) {
        env[key as keyof EnvProps] = value;
      }
    });
  },
  get typingDebounce() {
    return Number.isInteger(env.TYPING_DEBOUNCE) ? +env.TYPING_DEBOUNCE : 200;
  },
  get cookieDomain() {
    return env.COOKIE_DOMAIN;
  },
  get googleMapsApi() {
    return env.GOOGLE_MAPS_API_URL;
  },
  get clientApiBase() {
    return env.CLIENT_API_BASE || '/api';
  },
  get publicBaseUrlS3() {
    return env.S3_PUBLIC_BASE_URL;
  },
  get publicS3Bucket() {
    return env.S3_PUBLIC_FILE_BUCKET;
  },
  get enableSuggestions() {
    return env.ENABLE_SUGGESTIONS;
  },
  get enableCalendarEventsToday() {
    return env.ENABLE_CALENDAR_EVENTS_TODAY === 'true';
  },
};
