import { z } from 'zod';
import { Api } from '@axiom/ui';
import { SchemaEmail, SchemaPassword } from '@axiom/types';
import { AuthConstFlowTypesValueTypes } from '@axiom/const';
import { MfaVerificationSchema } from '@axiom/validation';

import ApiHelper from '../lib/api-helper';
import { EnvUtil } from '../utils/env-util';

export const readAuthRequestSchema = z.object({ email: SchemaEmail });
export type ReadAuthRequest = Required<z.infer<typeof readAuthRequestSchema>>;
export type ReadAuthResponse = {
  flowType?: AuthConstFlowTypesValueTypes;
  authRedirectUrl?: string;
};

export const createAuthPasswordRequestSchema = z
  .object({
    email: SchemaEmail,
    password: SchemaPassword,
  })
  .merge(MfaVerificationSchema);
export const createAuthTokenRequestSchema = z.object({
  token: z.string(),
});
export const updateTokenRequestSchema = z.object({
  password: SchemaPassword,
});
export type CreateAuthPasswordRequest = Required<
  z.infer<typeof createAuthPasswordRequestSchema>
>;
export type CreateAuthTokenRequest = Required<
  z.infer<typeof createAuthTokenRequestSchema>
>;
export type UpdateTokenRequest = Required<
  z.infer<typeof updateTokenRequestSchema>
>;

export type CreateAuthResponse = string;

class AuthApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.envoyApiUrl,
    });
  }

  createAuth(body: CreateAuthPasswordRequest | CreateAuthTokenRequest) {
    return super.write<{ data: CreateAuthResponse }>({
      endpoint: `/auth`,
      method: 'POST',
      body,
    });
  }

  readTokenIsValid(token: string) {
    return super.read<{ data: never }>({
      endpoint: `/auth/resets/${token}`,
      method: 'GET',
    });
  }

  updateTokenSetPassword(token: string, body: UpdateTokenRequest) {
    return super.write<{ data: never }>({
      endpoint: `/auth/resets/${token}`,
      method: 'PATCH',
      body,
    });
  }

  createForgotPassword(body: { email: string; RelayState?: string }) {
    return super.write<{ data: never }>({
      endpoint: `/auth/resets/`,
      method: 'POST',
      body,
    });
  }
}

export const AuthApi = new AuthApiClass();

const api = new ApiHelper('AUTH CRUD');

export const getAuthInfoForEmail = async (email: string) => {
  const { data } = await api.GET(`/auth/${email}`);
  return data;
};

export const loginAs = async (loginObj: {
  email: string;
  password: string;
}) => {
  const { data } = await api.POST(`${EnvUtil.envoyApiUrl}/auth`, loginObj);
  return data;
};

export const loginWithToken = async (token: string) => {
  const { data } = await api.PATCH(`/auth/tokenLogin/${token}`);
  return data;
};

export const requestPassword = async (email: string) => {
  const { data } = await api.POST(`/auth/resets`, { email });
  return data;
};

export const validateResetToken = async (token: string) => {
  const { data } = await api.GET(`/auth/resets/${token}`);
  return !!data;
};

export const resetPassword = async (resetPasswordForm: {
  token: string;
  password: string;
  passwordConfirm: string;
}) => {
  const { data } = await api.PATCH(
    `/auth/resets/${resetPasswordForm.token}`,
    resetPasswordForm
  );
  return data;
};
