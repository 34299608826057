import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const CheckboxIcon = ({
  alt,
  checked,
  className,
  height,
  name,
  width,
  disabled,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 27 27"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <path
      d="M0 0v27h27V0zm1 1h25v25H1z"
      color={disabled ? '#a1a1a1' : 'initial'}
    />
    {!!checked && (
      <path
        strokeWidth={2}
        stroke="#000000"
        d="M21.13 8.162L10.958 19.307l-4.133-3.52-.648.76 4.867 4.146L21.869 8.838z"
        color={disabled ? '#a1a1a1' : 'initial'}
      />
    )}
  </svg>
);

CheckboxIcon.propTypes = {
  alt: string,
  checked: bool,
  className: string,
  disabled: bool,
  height: oneOfType([number, string]),
  name: string,
  width: oneOfType([number, string]),
};

CheckboxIcon.defaultProps = {
  alt: null,
  checked: false,
  className: null,
  height: 27,
  name: 'CHECKBOX_ICON',
  width: 27,
  disabled: false,
};
