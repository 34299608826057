import React from 'react';
import { oneOf, node, string, number } from 'prop-types';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

const Div = ({ as, children, dataTest }) =>
  React.createElement(as, { dataTest }, children);

Div.propTypes = {
  as: string.isRequired,
  children: node.isRequired,
  dataTest: string.isRequired,
};

const StyledDiv = styled(Div)``;

export const Paragraph = ({ as, children, className, name, maxLines }) => (
  <StyledDiv
    as={as}
    className={AttrsHelper.formatClassname(
      'paragraph',
      maxLines && `max-lines-${maxLines}`,
      className
    )}
    data-test={name}
  >
    {children}
  </StyledDiv>
);

Paragraph.propTypes = {
  as: oneOf(['p', 'div', 'span']),
  children: node,
  className: string,
  /**
   * Cuts off text with an ellipse if the text goes passed a certain line count
   */
  maxLines: number,
  name: string,
};

Paragraph.defaultProps = {
  as: 'div',
  children: null,
  className: '',
  name: null,
  maxLines: null,
};
