import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const CheckmarkIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 27.156 25.37"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <rect
      width="27"
      height="6"
      rx="2"
      transform="matrix(0.588, -0.809, 0.809, 0.588, 6.422, 21.843)"
    />
    <rect
      width="13"
      height="6"
      rx="2"
      transform="matrix(0.809, 0.588, -0.588, 0.809, 3.517, 12.357)"
    />
  </svg>
);

CheckmarkIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

CheckmarkIcon.defaultProps = {
  alt: null,
  className: null,
  height: 25.37,
  name: 'CHECKMARK_ICON',
  width: 27.156,
};
