import React from 'react';
import { node } from 'prop-types';

import { RSCardBodyWrapper } from './CardLayoutStyles';

export const CardLayoutBody = ({ body }) => {
  if (body !== null) {
    return <RSCardBodyWrapper>{body}</RSCardBodyWrapper>;
  }
  return null;
};

CardLayoutBody.defaultProps = {
  body: null,
};
CardLayoutBody.propTypes = {
  body: node,
};
