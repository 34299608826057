import Ajv from 'ajv';
import { z } from 'zod';
import { Education } from '@axiom/const';
import { SchemaYear } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { AreaOfStudySchema } from './area-of-study';

const ajv = new Ajv(axiomValidationOptions());

export const EducationSchema = z.object({
  areaOfStudy: AreaOfStudySchema.nullish(),
  areaOfStudyId: z.string().uuid().nullish(),
  candidateId: z.string().uuid(),
  degree: z.string().max(255),
  id: z.string().uuid(),
  institution: z.string().max(255),
  isPrimary: z.boolean(),
  lawSchoolId: z.string().uuid(),
  yearAwarded: SchemaYear,
});

export const EducationEditSchema = EducationSchema.pick({
  areaOfStudyId: true,
  candidateId: true,
  degree: true,
  isPrimary: true,
  lawSchoolId: true,
  yearAwarded: true,
});

const educationCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    degree: {
      type: 'string',
      enum: Object.values(Education.DEGREES),
    },
    lawSchoolId: {
      type: 'string',
      format: 'uuid',
    },
    yearAwarded: {
      type: 'number',
      minimum: 1950,
      maximum: new Date().getFullYear() + 10,
    },
    isPrimary: {
      type: 'boolean',
    },
    areaOfStudyId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
  },
};

export const createEducationValidator = ajv.compile({
  ...educationCommonValidation,
  required: ['candidateId', 'degree', 'lawSchoolId', 'yearAwarded'],
});

export const updateEducationValidator = ajv.compile({
  ...educationCommonValidation,
  anyOf: [
    { required: ['degree'] },
    { required: ['lawSchoolId'] },
    { required: ['yearAwarded'] },
    { required: ['isPrimary'] },
    { required: ['areaOfStudyId'] },
  ],
});
