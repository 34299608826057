import React from 'react';

import { Experience } from '../Experience/Experience';

import {
  DSCandidateExpTextWrapper,
  DSCandidateHighlight,
} from './DSCandidateStyles';

export const DSCandidateExperienceView = (
  experience,
  showHighlightWrappers
) => {
  const { id, experienceVisible } = experience;
  return (
    <DSCandidateExpTextWrapper key={id}>
      {experienceVisible && showHighlightWrappers ? (
        <DSCandidateHighlight>
          <Experience experience={experience} />
        </DSCandidateHighlight>
      ) : (
        <Experience experience={experience} />
      )}
    </DSCandidateExpTextWrapper>
  );
};
