import React from 'react';
import { bool, number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const CalendarIcon = ({
  alt,
  className,
  height,
  name,
  width,
  withCheck,
  withCross,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 22 22"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <path d="M3.5 2C1.57 2 0 3.57 0 5.5v12C0 19.43 1.57 21 3.5 21h15c1.93 0 3.5-1.57 3.5-3.5v-12C22 3.57 20.43 2 18.5 2zm0 1h15C19.894 3 21 4.106 21 5.5V7H1V5.5C1 4.106 2.106 3 3.5 3zM1 8h20v9.5c0 1.394-1.106 2.5-2.5 2.5h-15A2.484 2.484 0 011 17.5z" />
    <path d="M5 0v5h2V0zm10 0v5h2V0z" />
    {!!withCheck && (
      <path d="M14.5985972,10.180824 C14.1702165,9.86687187 13.5708656,9.97234401 13.2666533,10.4149327 L9.67532364,15.6407326 L8.50670879,14.7856196 C8.07832812,14.4716675 7.47897722,14.5771396 7.17524243,15.0192355 C6.87103007,15.4613314 6.97323014,16.0798713 7.40161081,16.3933306 L9.34914303,17.819176 C9.77800128,18.1331281 10.3768746,18.027656 10.6806094,17.5850673 L14.8249656,11.5549191 C15.1287004,11.1133161 15.0269779,10.4947761 14.5985972,10.180824" />
    )}
    {!!withCross && (
      <path d="M8.42 10.168c-.32 0-.64.122-.885.367-.49.49-.49 1.278 0 1.768l1.768 1.767-1.768 1.768a1.25 1.25 0 000 1.77c.49.49 1.278.49 1.768 0l1.767-1.768 1.768 1.767c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L12.84 14.07l1.767-1.767c.49-.49.49-1.278 0-1.768a1.25 1.25 0 00-1.77 0l-1.767 1.768-1.767-1.768a1.244 1.244 0 00-.883-.367z" />
    )}
  </svg>
);

CalendarIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
  withCheck: bool,
  withCross: bool,
};

CalendarIcon.defaultProps = {
  alt: null,
  className: null,
  height: 22,
  name: 'CALENDAR_ICON',
  width: 22,
  withCheck: false,
  withCross: false,
};
