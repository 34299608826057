import Ajv from 'ajv';
import { z } from 'zod';
import {
  CountryCodesConst,
  Opportunity,
  OpportunitiesConst,
  YesNoMaybeConst,
  PracticeArea,
  FocusArea,
} from '@axiom/const';
import {
  SchemaDate,
  SchemaLocation,
  SchemaTimestamp,
  SchemaCurrency,
} from '@axiom/types';

import { ContactSchema } from './contacts';
import { EventSchema } from './event';
import { axiomValidationOptions } from './options';
import { PracticeAreaSchema } from './practice-area';
import { UserSchema } from './user';
import { arrayValidatorCreator } from './general';
import { AccountSchema } from './account';
import { PositionSchema } from './positions';
import { LanguageSchema } from './language';
import { OpportunityLanguageSchema } from './opportunity-language';
import { getMaxFloatValue } from './utils';
import { TimeToSubmissionSchema } from './time-to-submission';

const { StageCodes, TransactionSubType } = OpportunitiesConst;

export const StageCodeValues = Object.values(
  Opportunity.STAGE_CODE_MAP
) as NonEmptyArray<string>;

const StageValues = Object.values(Opportunity.STAGES) as NonEmptyArray<string>;
const TransactionSubTypeValues = Object.values(
  TransactionSubType
) as NonEmptyArray<string>;

const TalentInMindValues = Object.keys(
  YesNoMaybeConst.YesNoMaybe
) as NonEmptyArray<keyof typeof YesNoMaybeConst.YesNoMaybe>;

const CountryCodeKeys = Object.keys(
  CountryCodesConst.CountryCodesAbbreviations
) as NonEmptyArray<string>;

const workLocationTypes = Object.keys(
  OpportunitiesConst.WorkLocation
) as NonEmptyArray<string>;

export const OpportunitySchema = z
  .object({
    account: AccountSchema,
    accountId: z.string().uuid(),
    accountName: z.string().max(255).nullable(),
    accountSalesforceId: z.string().max(255).nullable(),
    addressCity: z.string().nullable(),
    addressState: z.string().nullable(),
    addressZip: z.string().nullable(),
    addressCountryCode: z.enum(CountryCodeKeys).nullable(),
    aiUnmatchableMessage: z.string().nullish(),
    amount: z.number().nullable(),
    billingCurrency: z.string().max(255).nullable(),
    billingEstimatedUnits: z.string().max(255).nullable(),
    billingName: z.string().max(255).nullable(),
    businessTeam: z.string().max(255).nullable(),
    clientName: z.string().max(255).nullable(),
    closedDate: SchemaDate.nullable(),
    closedLostNotes: z.string().max(255).nullable(),
    competitorType: z.string().max(255).nullable(),
    competitorView: z.string().max(255).nullable(),
    contractedEndDate: SchemaDate.nullable(),
    confidential: z.boolean().nullable(),
    confidentialUpdatedBy: z.string().uuid().nullable(),
    confidentialUpdatedAt: SchemaTimestamp.nullable(),
    confidentialLastUpdatedByUser: UserSchema.nullable(),
    country: z.string().nullable(),
    createdAt: SchemaTimestamp,
    cultureSkills: z.string().max(255).nullable(),
    currency: SchemaCurrency.nullable(),
    description: z.string().nullable(),
    directEligible: z.boolean().default(false),
    stopDirectInterestEmails: z.boolean().default(false),
    endDate: SchemaDate.nullable(),
    engagementType: z.string().max(255).nullable(),
    events: z.array(EventSchema).nullish(),
    firstCandidateSubmittedAt: SchemaTimestamp.nullable(),
    firstCandidateSelectedOrFurtherAt: SchemaTimestamp.nullable(),
    forecastCategory: z.string().max(255).nullable(),
    fulfillmentStatus: z.string().max(255).nullable(),
    hasBeenAIMatched: z.boolean().nullish(),
    id: z.string().uuid(),
    inadequateSupplyCategory: z.string().max(255).nullable(),
    integrationId: z.string().max(255).nullable(),
    isAiMatchable: z.boolean().nullish(),
    isClosed: z.boolean().default(false),
    isExcludedFromFeed: z.boolean(),
    isFalseStart: z.boolean(),
    isFulfillmentActive: z.boolean(),
    isInterviewing: z.boolean(),
    isRemote: z.boolean(),
    jobName: z.string().max(255).nullable(),
    languages: z
      .array(OpportunityLanguageSchema.extend({ language: LanguageSchema }))
      .nullish(),
    lastUpdatedBy: z.string().uuid(),
    locationPoint: z.object({}).nullish(),
    locationCityStateCountry: z.string().nullable(),
    isQualificationComplete: z.boolean(),
    madeAvailableToFeedAt: SchemaDate.nullable(),
    madeAvailableToFeedNotificationAt: SchemaDate.nullable(),
    matterId: z.string().max(255).nullable(),
    nextStep: z.string().max(255).nullable(),
    offering: z.string().max(255).nullable(),
    onsiteRemote: z.string().max(255).nullable(),
    opportunityOwnerSalesforceId: z.string().max(255).nullable(),
    ownerUser: UserSchema,
    ownerUserId: z.string().uuid(),
    positions: z.array(PositionSchema),
    practiceAreaId: z.string().uuid(),
    practiceArea: PracticeAreaSchema.nullish(),
    qualifiedAt: SchemaTimestamp.nullable(),
    reasonLost: z.string().nullable(),
    region: z.string().max(255).nullable(),
    renewal: z.string().max(255).nullable(),
    renewalContract: z.string().max(255).nullable(),
    requiredBillingHoursPerWeek: z.number().nullish(),
    requiredNumResources: z.number().int().nullable(),
    salesCloseDate: SchemaDate.nullable(),
    salesforceAccountId: z.string().max(255).nullable(),
    salesforceContact: ContactSchema,
    salesforceContactId: z.string().uuid(),
    salesforceContractId: z.string().max(255).nullable(),
    salesforceCxLead: ContactSchema,
    salesforceCxLeadId: z.string().uuid(),
    salesforceId: z.string().max(255).nullable(),
    salesforceQuoteId: z.string().max(255).nullable(),
    salesLead: UserSchema,
    salesLeadId: z.string().uuid(),
    salesNote: z.string().nullable(),
    stage: z.enum(StageValues).nullable(),
    stageCode: z.enum(StageCodeValues).nullable(),
    startDate: SchemaDate.nullable(),
    statusUpdatedAt: SchemaTimestamp,
    excludedFromFeedUpdatedAt: SchemaTimestamp.nullable(),
    excludedFromFeedUserOverride: z.boolean().nullish(),
    allowInterest: z.boolean().nullish().default(false),
    excludedFromFeedUpdatedBy: z.string().uuid().nullable(),
    submissionId: z.string().uuid(),
    submissionLastUpdatedAt: SchemaTimestamp,
    submissionPublishedAt: SchemaTimestamp.nullable(),
    talentInMind: z.enum(TalentInMindValues).nullable(),
    timeToSubmission: TimeToSubmissionSchema.nullable(),
    transactionSubType: z.enum(TransactionSubTypeValues).nullable(),
    requiredTalentCountryCode: z.enum(CountryCodeKeys).nullable(),
    updatedAt: SchemaTimestamp,
    useCase: z.string().max(255).nullable(),
    workLocation: z.enum(workLocationTypes).nullable(),
  })
  .merge(SchemaLocation);

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const ajvNoCoerce = new Ajv({
  ...axiomValidationOptions(),
});

// VALIDATIONS
const opportunityCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    accountName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    accountSalesforceId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    amount: {
      type: ['number', 'null'],
      maximum: Number.MAX_SAFE_INTEGER,
    },
    billingCurrency: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    billingEstimatedUnits: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    businessTeam: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    clientName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    closedLostNotes: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    competitorType: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    competitorView: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    confidential: {
      type: 'boolean',
    },
    cultureSkills: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    currency: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    description: {
      type: ['string', 'null'],
    },
    contractedEndDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    engagementType: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    firstCandidateSubmittedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
    firstCandidateSelectedOrFurtherAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
    forecastCategory: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    fulfillmentStatus: {
      type: ['string', 'null'],
      enum: OpportunitiesConst.FulfillmentStatuses.enumValues.map(
        value => value.v
      ),
    },
    inadequateSupplyCategory: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    integrationId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    isQualificationComplete: {
      type: ['boolean', 'null'],
    },
    isRemote: {
      type: ['boolean', 'null'],
    },
    jobName: {
      type: 'string',
      maxLength: 255,
    },
    matterId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    nextStep: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    renewal: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    renewalContract: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    offering: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    opportunityOwnerSalesforceId: {
      type: ['string', 'null'],
    },
    ownerUserId: {
      type: ['string', 'null'],
    },
    practiceAreaId: {
      type: ['string', 'null'],
      format: 'uuid',
      maxLength: 255,
    },
    qualifiedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
    reasonLost: {
      type: ['string', 'null'],
    },
    region: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    requiredNumResources: {
      type: ['integer', 'null'],
    },
    salesCloseDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    salesforceAccountId: {
      type: ['string', 'null'],
    },
    salesforceContactId: {
      type: ['string', 'null'],
    },
    salesforceContractId: {
      type: ['string', 'null'],
    },
    salesforceCxLeadId: {
      type: ['string', 'null'],
    },
    salesforceId: {
      type: 'string',
      maxLength: 255,
    },
    salesLeadId: {
      type: ['string', 'null'],
    },
    salesNote: {
      type: ['string', 'null'],
    },
    stage: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    startDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    statusUpdatedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
    useCase: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    salesforceQuoteId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    accountId: {
      type: ['string', 'null'],
    },
    directEligible: {
      type: 'boolean',
    },
    stopDirectInterestEmails: {
      type: 'boolean',
    },
    addressCountryCode: {
      type: ['string', 'null'],
      enum: CountryCodeKeys.concat([null]),
    },
    requiredTalentCountryCode: {
      type: ['string', 'null'],
      enum: CountryCodeKeys.concat([null]),
    },
    addressStreet: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressCity: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressState: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressZip: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    drivingNeed: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    opportunityLostCoveragePlan: {
      type: ['string', 'null'],
    },
    workLocation: {
      type: ['string', 'null'],
      enum: [...Object.keys(OpportunitiesConst.WorkLocation), null],
    },
    talentInMind: {
      type: ['string', 'null'],
      enum: [...Object.keys(YesNoMaybeConst.YesNoMaybe), null],
    },
    excludedFromFeedUserOverride: {
      type: ['boolean', 'null'],
    },
    allowInterest: {
      type: ['boolean', 'null'],
    },
    clientBudgetConfirmed: {
      type: ['string', 'null'],
      enum: [...Object.keys(OpportunitiesConst.ClientBudgetConfirmed), null],
    },
    clientBudgetMin: {
      type: ['number', 'null'],
      minimum: 0,
      maximum: getMaxFloatValue(18, 2),
    },
    clientBudgetMax: {
      type: ['number', 'null'],
      minimum: 0,
      maximum: getMaxFloatValue(18, 2),
    },
    transactionSubType: {
      type: ['string', 'null'],
      enum: [
        TransactionSubType.Backfill,
        TransactionSubType.AdditionalResource,
        TransactionSubType.NewDeal,
        null,
      ],
    },
  },
};
const createOpportunityValidation = {
  ...opportunityCommonValidation,
};

const replaceOpportunityValidation = {
  ...opportunityCommonValidation,
  properties: {
    ...opportunityCommonValidation.properties,
    practiceAreaId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    primaryPracticeArea: {
      type: ['string', 'null'],
      enum: [
        ...(Object.values(PracticeArea) as string[]),
        'unknown',
        'Unknown',
        null,
      ],
    },
    primaryFocusArea: {
      type: ['string', 'null'],
      enum: [
        ...(Object.values(FocusArea) as string[]),
        'unknown',
        'Unknown',
        null,
      ],
    },
  },
};

const updateOpportunityValidation = {
  ...opportunityCommonValidation,
  anyOf: Object.keys(opportunityCommonValidation.properties).map(key => ({
    required: [key],
  })),
};

const opportunityFilterValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    id: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    practiceAreaId: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    stage: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    stageCode: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    businessTeam: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    salesLeadId: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    salesforceId: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    ownerUserId: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    startDate: {
      type: 'object',
      additionalProperties: false,
      properties: {
        end: {
          type: 'string',
          format: 'date',
        },
      },
    },
    salesCloseDate: {
      type: 'object',
      additionalProperties: false,
      properties: {
        start: {
          type: 'string',
          format: 'date',
        },
        end: {
          type: 'string',
          format: 'date',
        },
      },
    },
    legal: {
      type: 'boolean',
    },
    nonLegal: {
      type: 'boolean',
    },
    accountName: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    engagementType: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    offering: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    isFulfillmentActive: {
      type: 'boolean',
    },
  },
};

// VALIDATORS
export const createOpportunityValidator = ajv.compile(
  createOpportunityValidation
);
export const createOpportunityArrayValidator = arrayValidatorCreator(
  createOpportunityValidation
);

export const replaceOpportunityValidatorCreator = ({
  ajvInstance = ajv,
  additionalOpts = {},
} = {}) =>
  ajvInstance.compile({ ...replaceOpportunityValidation, ...additionalOpts });

export const replaceOpportunityValidator = replaceOpportunityValidatorCreator();

// ONLY CONDITIONALLY USE THIS - for boomi endpoints only
export const replaceOpportunityAdditionalPropertiesValidator =
  replaceOpportunityValidatorCreator({
    ajvInstance: new Ajv({
      ...axiomValidationOptions(),
      removeAdditional: true,
    }),
    additionalOpts: {
      additionalProperties: false,
    },
  });

export const updateOpportunityValidator = ajv.compile(
  updateOpportunityValidation
);

export const opportunityFilterValidator = ajv.compile({
  ...opportunityFilterValidation,
});

export const opportunitySortTermValidator = ajvNoCoerce.compile({
  type: 'object',
  additionalProperties: false,
  properties: {
    sort: {
      type: 'string',
      enum: Opportunity.SORT_COLUMNS,
    },
  },
});

export const opportunityStatsFilterValidator = ajv.compile({
  type: 'object',
  additionalProperties: false,
  properties: {
    ...opportunityFilterValidation.properties,
    'positions.startDate': {
      type: 'object',
      additionalProperties: false,
      properties: {
        start: {
          type: 'string',
          format: 'date',
        },
        end: {
          type: 'string',
          format: 'date',
        },
      },
    },
  },
});

export const stageCodeValidation = {
  type: 'array',
  items: {
    type: 'string',
    enum: StageCodes,
  },
  uniqueItems: true,
};

export const stageCodeValidator = ajv.compile(stageCodeValidation);
