export enum Pronouns {
  HeHim = 'HeHim',
  SheHer = 'SheHer',
  TheyThem = 'TheyThem',
}

export enum DisplayPronouns {
  HeHim = 'He/Him',
  SheHer = 'She/Her',
  TheyThem = 'They/Them',
}

export const PronounsDropdownOptions = [
  { display: DisplayPronouns.HeHim, value: Pronouns.HeHim },
  { display: DisplayPronouns.SheHer, value: Pronouns.SheHer },
  { display: DisplayPronouns.TheyThem, value: Pronouns.TheyThem },
];
