import React, { Context } from 'react';
import once from 'lodash/once';

import { FormPropsChildren } from './FormTypes';

export interface FormContextType<FormValues> {
  getSchemaProperty: (propName: string) => SchemaDefinitionProperty;
  fireReset: FormPropsChildren<FormValues>['fireReset'];
  fireSubmit: FormPropsChildren<FormValues>['fireSubmit'];
  values: FormValues;
}

export const invokeFormContext = once(
  <FormValues,>() =>
    React.createContext({
      getSchemaProperty: () => {
        throw new Error(
          "You're outside a form context. This won't work as expected"
        );
      },
      fireReset: () => {
        throw new Error(
          "You're outside a form context. This won't work as expected"
        );
      },
      fireSubmit: () => {
        throw new Error(
          "You're outside a form context. This won't work as expected"
        );
      },
      values: null,
    }) as unknown as Context<FormContextType<FormValues>>
);

export const useFormContext = () => {
  const context = React.useContext(invokeFormContext());
  if (!context) {
    throw new Error('useFormContext must be used under Form');
  }
  return context;
};
