import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const ExperiencePlaceholderIcon = ({
  alt,
  className,
  height,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 104 81"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <rect fill="#BEBEBE" y="13" width="104" height="68" rx="8" />
    <g fill="#FFF">
      <path d="M0 37h104v4H0z" />
      <path d="M43 37v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V37h1a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H42a4 4 0 0 1-4-4v-8a4 4 0 0 1 4-4h1z" />
    </g>
    <path
      d="M67 24V9a2 2 0 0 0-2-2H39a2 2 0 0 0-2 2v15h-1a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h32a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4h-1z"
      fill="#BEBEBE"
    />
  </svg>
);

ExperiencePlaceholderIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

ExperiencePlaceholderIcon.defaultProps = {
  alt: null,
  className: null,
  height: 81,
  name: 'EXPERIENCE_PLACEHOLDER_ICON',
  width: 104,
};
