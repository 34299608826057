import { UserDto } from '@axiom/ui';

import ApiHelper from '../lib/api-helper';

const api = new ApiHelper('Users');

export const updateUser = async user => {
  const requestBody = new UserDto(user);
  const { data } = await api.PATCH(`/users/${user.id}`, requestBody.export());
  return { data };
};
