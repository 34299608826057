import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  CookieUtil,
  Form,
  Gutter,
  Input,
  Grid,
  GridRow,
  GridColumn,
  SmallHeader,
  CondensedLarge,
  AxiomLogo,
} from '@axiom/ui';

import sideImageUrl from '../public/svg/spyglass.svg?url';
import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { WindowUtil } from '../utils/WindowUtil';
import { ClientLoginTwoColumnLayout } from '../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { ReadAuthRequest, readAuthRequestSchema } from '../api/auth-api';

const { getFullRedirect, getUrlWithRelayState } = WindowUtil;

export const EnterEmailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const relayState = searchParams.get('RelayState');

  useEffect(() => {
    if (!CookieUtil.isUserExpired()) {
      navigate(`/sso${relayState ? `${getFullRedirect(location)}` : ''}`, {
        replace: true,
      });
    }
  }, []);

  if (!CookieUtil.isUserExpired()) {
    return <LoadingTreatment />;
  }

  return (
    <ClientLoginTwoColumnLayout sideImageUrl={sideImageUrl}>
      <Gutter bottom="64px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
      <Grid centered>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Gutter bottom="24px" className="text-align-center">
              <AxiomLogo width="230px" />
            </Gutter>
            <Gutter bottom="24px">
              <SmallHeader>Let's find your Axiom account</SmallHeader>
            </Gutter>
            <Gutter bottom="24px">
              <CondensedLarge>
                We'll confirm that your Axiom account is associated with this
                email.
              </CondensedLarge>
            </Gutter>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Form
              name="EMAIL_FORM"
              onSubmit={(formData: ReadAuthRequest) => {
                navigate(getUrlWithRelayState(`./${formData.email}`));
              }}
              schema={readAuthRequestSchema}
            >
              {({ fireSubmit }) => {
                return (
                  <>
                    <Gutter bottom="16px">
                      <Input name="email" label="Email" />
                    </Gutter>
                    <Button fluid onClick={fireSubmit} name="next-button">
                      Find my account
                    </Button>
                  </>
                );
              }}
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
      <Gutter bottom="64px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
    </ClientLoginTwoColumnLayout>
  );
};
