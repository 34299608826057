import React from 'react';
import {
  AxiomLogo,
  CondensedLarge,
  CondensedMedium,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  SmallHeader,
  Well,
} from '@axiom/ui';
import { Link, useLocation, useParams } from 'react-router-dom';

import imageUrl from '../../public/svg/profile-skeleton.svg?url';
import { ClientLoginTwoColumnLayout } from '../../layouts/ClientLogin/ClientLoginTwoColumnLayout';

export const CheckYourEmail = () => {
  const location = useLocation();
  const { providedEmail } = useParams();

  return (
    <ClientLoginTwoColumnLayout sideImageUrl={imageUrl}>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
      <Grid centered>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Gutter bottom="24px" className="text-align-center">
              <AxiomLogo width="230px" />
            </Gutter>
            <Gutter bottom="24px">
              <SmallHeader>Check your email</SmallHeader>
            </Gutter>
            <Gutter bottom="24px">
              <CondensedLarge name="SUBTEXT">
                {location.pathname.includes('check-email')
                  ? "We found your account but it looks like you need to complete an additional step before you can sign in. We've sent  instructions to your email."
                  : "We've sent sign-in instructions to your email."}
              </CondensedLarge>
            </Gutter>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            {providedEmail && (
              <Gutter bottom="8px">
                <Well name="EMAIL">
                  <CondensedLarge>{providedEmail}</CondensedLarge>
                </Well>
              </Gutter>
            )}

            <Gutter bottom="24px">
              <CondensedMedium>
                <Link to="/" data-test="BACK">
                  Not you? Sign in with a different account.
                </Link>
              </CondensedMedium>
            </Gutter>
            <CondensedMedium name="CHECK_EMAIL_BODY_COPY">
              If you don't receive that email within an hour, please check your
              spam folder before reaching out to Axiom Answers for{' '}
              <a href="mailto:client.answers@axiomlaw.com">Clients</a> or{' '}
              <a href="mailto:answers@axiomlaw.com">Talent</a> for assistance.
            </CondensedMedium>
          </GridColumn>
        </GridRow>
      </Grid>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
    </ClientLoginTwoColumnLayout>
  );
};
