import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaBadges } from '@axiom/types';

import { ContactsOpportunitySchema } from './contacts-opportunities';
import { ExperienceSchema } from './experience';
import { axiomValidationOptions } from './options';
import { OpportunitySchema } from './opportunity';
import { PositionSchema } from './positions';
import { AfcSubmissionPositionSchema } from './afc-positions';
import { CandidateSchema } from './candidate';
import {
  BaseCandidateOpportunitySchema,
  CandidateOpportunitySchema,
} from './candidate-opportunities';
import { ContactSchema } from './contacts';
import { PracticeAreaSchema } from './practice-area';
import { AccountTypeValues } from './account';

export const SubmissionExperienceSchema = ExperienceSchema.partial().extend({
  accountConfidential: z.boolean(),
  submissionDescription: z.string().nullish(),
  isHighlighted: z.boolean(),
  experienceVisible: z.boolean().default(true),
});

export const SubmissionCandidateSchema = CandidateSchema.merge(
  CandidateOpportunitySchema
)
  .pick({
    // from candidate
    id: true,
    firstName: true,
    middleName: true,
    lastName: true,
    calculatedDisplayName: true,
    calculatedFirstName: true,
    calculatedLastName: true,
    calendar: true,
    practiceStartYear: true,
    candidateStatus: true,
    lawSchool: true,
    occupationType: true,
    barredLocations: true,
    certifications: true,
    degrees: true,
    languages: true,
    ownerUserId: true,
    ownerUser: true,
    yearsOfExperience: true,
    publicCandidateSummary: true,
    candidateHeader: true,
    practiceArea: true,
    lawFirms: true,
    addressCity: true,
    addressState: true,
    addressCountry: true,
    publicRefId: true,
    lawDegreeSchools: true,
    isAvailableToConsult: true,
    isSpotlightedLawyer: true,
    weeklyAvailability: true,
    weeklyAvailabilityLabel: true,
    profileImageKey: true,
    profileImageName: true,
    industries: true,
    pronouns: true,
    // from candidate opportunity
    isActiveForSubmission: true,
    displayBillingRate: true,
    proposedHourlyRate: true,
    submissionDescription: true,
    submissionRank: true,
  })
  .extend({
    position: PositionSchema.pick({
      id: true,
      name: true,
      billingHoursPerDay: true,
    })
      .extend({
        candidateOpportunities: CandidateOpportunitySchema,
      })
      .nullish(),
    badges: SchemaBadges,
  });

export const SubmittedCandidateOpportunitySchema = CandidateSchema.merge(
  BaseCandidateOpportunitySchema
);

/**
 * SchemaProjectState is shared between two schemas in this file
 * BaseSubmissionSchema and AfcSubmissionSchema
 *
 * AfcSubmissionSchema can be derived from BaseSubmissionSchema
 * but this causes Zod to complain about size limit
 * By directly deriving from OpportunitySchema and adding in
 * SchemaProjectState we get around the size limit issue.
 * */
const SchemaProjectState = z.string().max(255).nullable();

const BaseSubmissionSchema = OpportunitySchema.pick({
  currency: true,
  id: true,
  isQualificationComplete: true,
  jobName: true,
  practiceArea: true,
  salesLead: true,
  salesforceContactId: true,
  submissionId: true,
  submissionLastUpdatedAt: true,
  accountId: true,
  accountName: true,
  clientName: true,
  createdAt: true,
  isClosed: true,
  salesLeadId: true,
  stage: true,
  startDate: true,
  updatedAt: true,
  endDate: true,
  submissionPublishedAt: true,
}).extend({
  positions: z.array(
    PositionSchema.extend({
      candidateOpportunities: z.array(CandidateOpportunitySchema),
    })
  ),
  projectState: SchemaProjectState,
});

export const SubmissionSchema = BaseSubmissionSchema.extend({
  contactsOpportunities: z.array(
    ContactsOpportunitySchema.pick({
      id: true,
      contactId: true,
      opportunityId: true,
      role: true,
    }).extend({
      contact: ContactSchema.pick({
        id: true,
        fullName: true,
        firstName: true,
        lastName: true,
        email: true,
        displayRole: true,
      }),
    })
  ),
});

export const AfcSubmissionSchema = OpportunitySchema.pick({
  confidential: true,
  currency: true,
  id: true,
  isQualificationComplete: true,
  jobName: true,
  salesLead: true,
  salesforceContactId: true,
  submissionId: true,
  submissionLastUpdatedAt: true,
}).extend({
  accountType: z.enum(AccountTypeValues).nullable(),
  projectState: SchemaProjectState,
  positions: z.array(AfcSubmissionPositionSchema),
  practiceArea: PracticeAreaSchema.pick({
    id: true,
    name: true,
    parentId: true,
  }).nullish(),
  primaryFocusArea: z.string().nullish(),
  primaryPracticeArea: z.string().nullish(),
  contacts: z.array(
    ContactSchema.pick({
      displayRole: true,
      email: true,
      firstName: true,
      fullName: true,
      id: true,
      lastName: true,
    })
  ),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const updateSubmissionValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    newSubmissionType: { type: 'boolean' },
    candidates: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        properties: {
          candidateId: { type: 'string', format: 'uuid' },
          isActiveForSubmission: { type: 'boolean' },
          submissionRank: { type: 'integer' },
          submissionDescription: { type: 'string' },
          highlightedExperiences: {
            type: ['array', 'null'],
            items: {
              type: 'object',
              additionalProperties: false,
              properties: {
                id: {
                  type: 'string',
                  format: 'uuid',
                },
                isHighlighted: {
                  type: ['boolean', 'null'],
                },
                experienceVisible: {
                  type: 'boolean',
                },
                submissionDescription: {
                  type: ['string', 'null'],
                },
              },
            },
          },
          newSubmissionType: {
            type: ['boolean', 'null'],
          },
        },
        required: ['candidateId'],
        anyOf: [
          { required: ['isActiveForSubmission'] },
          { required: ['submissionRank'] },
          { required: ['submissionDescription'] },
          { required: ['highlightedExperiences'] },
        ],
      },
    },
  },
  anyOf: [{ required: ['candidates'] }],
};

const sendSubmissionEmailValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    contactIds: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
  },
  required: ['contactIds'],
};

export const updateSubmissionValidator = ajv.compile(
  updateSubmissionValidation
);

export const sendSubmissionEmailValidator = ajv.compile(
  sendSubmissionEmailValidation
);
