import React from 'react';
import {
  Layout,
  CardSection,
  Grid,
  GridRow,
  GridColumn,
  useBreakpoint,
} from '@axiom/ui';

import { ClientLoginOuterLayout } from './ClientLoginOuterLayout';

export const ClientLoginTwoColumnLayout: React.FC<{
  sideImageUrl?: string;
  children: React.ReactNode;
}> = ({ sideImageUrl, children }) => {
  const { isLargeScreen } = useBreakpoint();
  return (
    <ClientLoginOuterLayout>
      <CardSection
        name="CLIENT_LOGIN_TWO_COLUMN_LAYOUT_SECTION"
        background={
          isLargeScreen ? { image: sideImageUrl, position: 'right center' } : {}
        }
      >
        <Grid>
          <GridRow>
            <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
              <Layout position="center stretch" direction="vertical" stretched>
                {children}
              </Layout>
            </GridColumn>
          </GridRow>
        </Grid>
      </CardSection>
    </ClientLoginOuterLayout>
  );
};
