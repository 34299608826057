import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { arrayOf, shape } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';
import { SkillsUtil } from '../../../utils/skills-util';
import { AxiomTheme } from '../../../theme/axiom-theme';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Header3 } from '../../content/Header3/Header3';
import { Header4 } from '../../content/Header4/Header4';
import { Text } from '../../content/Text/Text';

import { DSCandidateToggleButton } from './DSCandidateToggleButton';

const DSSkillsContainer = styled.div`
  border-top: 1px solid ${AxiomTheme.neutrals.neutral40};
  margin-top: 1rem;
  padding-top: 1rem;
`;

// skill line break on the comma
const SkillTextWrapper = styled.span`
  @media screen {
    display: inline-block;
    padding-right: 0.7em;
    white-space: nowrap;
  }
`;

const getCategorySkills = experiences =>
  SkillsUtil.categorySkills(
    experiences.reduce((data, item) => [...data, ...(item.skills || [])], [])
  );

class DSCandidateSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      skills: getCategorySkills(props.experiences),
      prevExperiences: props.experiences,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { experiences } = nextProps;

    if (experiences === prevState.prevExperiences) {
      return null;
    }

    return {
      skills: getCategorySkills(experiences),
      prevExperiences: experiences,
    };
  }

  toggleExpanded = () =>
    this.setState(state => ({ expanded: !state.expanded }));

  render() {
    const { expanded, skills } = this.state;

    if (skills.length === 0) return null;

    return (
      <DSSkillsContainer
        className="pdf-no-break"
        data-test={DataTestUtil.format('Skills_container')}
      >
        <DSCandidateToggleButton
          expanded={expanded}
          onClick={this.toggleExpanded}
        >
          <Text as="span" className="text-align-center" subtle>
            {expanded ? 'Hide Skills' : 'Show Skills'}
          </Text>
        </DSCandidateToggleButton>
        <div className={expanded ? null : 'show-print'}>
          <Header3>Skills</Header3>
          {skills.map(category => (
            <Fragment key={category.id}>
              <Header4>{category.name}</Header4>
              {category.children.length > 0 && (
                <Gutter bottom="1rem" gutterType="margin">
                  {category.children.map((skill, i, arr) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <SkillTextWrapper key={`${skill.name}_${i}`}>
                      {skill.name}
                      {i + 1 < arr.length && ', '}
                    </SkillTextWrapper>
                  ))}
                </Gutter>
              )}
            </Fragment>
          ))}
        </div>
      </DSSkillsContainer>
    );
  }
}

DSCandidateSkills.propTypes = {
  experiences: arrayOf(shape({})).isRequired,
};

export default DSCandidateSkills;
