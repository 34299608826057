import React from 'react';
import styled from 'styled-components';
import { arrayOf, node, oneOf, oneOfType, string } from 'prop-types';
import capitalize from 'lodash/capitalize';

import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { ResponsiveConfig } from '../../../configs/responsive-config';

const VisibleWrapper = styled.div``;

export const isVisible = (only, breakpoints) => {
  const viewportClasses = typeof only === 'string' ? only.split(' ') : only;
  return viewportClasses.some(breakpoint => {
    const formattedBreakpoint = breakpoint
      ? `is${capitalize(breakpoint[0])}${breakpoint.slice(1)}`
      : breakpoint;
    return !!breakpoints[formattedBreakpoint];
  });
};
export const Visible = ({ as, children, name, only }) => {
  const breakpoints = useBreakpoint();

  if (!isVisible(only, breakpoints)) {
    return null;
  }

  return (
    <VisibleWrapper as={as} data-test={name}>
      {children}
    </VisibleWrapper>
  );
};

Visible.propTypes = {
  as: oneOf(['div', 'span']),
  children: node,
  name: string,
  /**
   * Usage:
   * only="desktop tablet"
   * only="largeScreen"
   * only="smallScreen"
   *
   * For Storybook, it uses this syntax
   * only={["desktop"]}
   */
  only: oneOfType([
    string,
    arrayOf(oneOf(Object.values(ResponsiveConfig.viewports))),
  ]).isRequired,
};

Visible.defaultProps = {
  as: 'div',
  children: null,
  name: null,
};
