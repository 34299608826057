import React, { useEffect, useState } from 'react';

import { ToastUtil } from '../../../utils/toast-util';
import { Gutter } from '../../layout/Gutter/Gutter';

import { ToastsItem } from './ToastsItem';

export const Toasts = () => {
  const [, setLastUpdatedAt] = useState(new Date());
  useEffect(() => {
    const subscription = ToastUtil.listen(() => {
      setLastUpdatedAt(new Date());
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="toast-container">
      {ToastUtil.getAll().map(toast => (
        <Gutter top="10px" key={toast.addedOn}>
          <ToastsItem
            dismissible={toast.dismissible}
            name={toast.name}
            onClose={() => {
              ToastUtil.remove(toast);
            }}
            type={toast.type}
          >
            {toast.children}
          </ToastsItem>
        </Gutter>
      ))}
    </div>
  );
};
