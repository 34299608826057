import {
  CountryCodesConst,
  CurrencyLabels,
  CurrencyMap,
  CountryCodesAbbreviationsType,
  CurrencyCodeKeyTypes,
  CurrencySymbolTypes,
  CurrencyCodeValueTypes,
  CurrencyDelimiters,
} from '@axiom/const';

const {
  codes,
  currencySymbols,
  currenciesWithLabel,
  currenciesDelimitersByCurrencyCode,
} = CurrencyMap;
const { CountryCodesAbbreviations } = CountryCodesConst;

const countryToCurrency = {
  [CountryCodesAbbreviations.AU]: codes.AUD,
  [CountryCodesAbbreviations.CA]: codes.CAD,
  [CountryCodesAbbreviations.CH]: codes.CHF,
  [CountryCodesAbbreviations.SG]: codes.SGD,
  [CountryCodesAbbreviations.DE]: codes.EUR,
  [CountryCodesAbbreviations.HK]: codes.HKD,
  [CountryCodesAbbreviations.UK]: codes.GBP,
  [CountryCodesAbbreviations.US]: codes.USD,
};

const currencyToCountry = Object.entries(countryToCurrency).reduce(
  // @ts-expect-error Needs new type
  (
    acc,
    [countryCode, currencyCode]: [
      CountryCodesAbbreviationsType,
      CurrencyCodeKeyTypes,
    ]
  ): Record<CurrencyCodeKeyTypes, CountryCodesAbbreviationsType> => {
    acc[currencyCode] = countryCode;
    return acc;
  },
  {} as Record<CurrencyCodeKeyTypes, CountryCodesAbbreviationsType>
);

export const CurrencyUtil = {
  convertToSymbol: (
    code?: CurrencyCodeKeyTypes
  ): CurrencySymbolTypes | null => {
    return currencySymbols?.[code] ?? null;
  },

  getCountryCodeByCurrency: (
    currency: CurrencyCodeKeyTypes
  ): CountryCodesAbbreviationsType => {
    // @ts-expect-error Needs new type
    return currencyToCountry?.[currency] ?? null;
  },

  getCurrencyWithLabel: (code?: CurrencyCodeKeyTypes): CurrencyLabels => {
    return currenciesWithLabel?.[code] ?? null;
  },

  getCurrencyWithLabelByCountryCode: (
    countryCode: CountryCodesAbbreviationsType = CountryCodesAbbreviations.US
  ): CurrencyLabels => {
    return currenciesWithLabel?.[countryToCurrency?.[countryCode]] ?? null;
  },

  getCurrencyCodeByCountryCode: (
    countryCode: CountryCodesAbbreviationsType = CountryCodesAbbreviations.US
  ): CurrencyCodeKeyTypes => {
    return countryToCurrency?.[countryCode] ?? null;
  },

  getFormattedCurrency: (
    value: number | string,
    currencyCode: CurrencyCodeValueTypes = codes.USD
  ): string => {
    const delimiter: CurrencyDelimiters =
      currenciesDelimitersByCurrencyCode[currencyCode];
    let currency = `${value}`;

    // Already formatted === START
    const delimiterPattern = new RegExp(delimiter, 'g');
    const countDelimiters = (currency.match(delimiterPattern) || []).length;
    if (countDelimiters >= 1) return currency;
    // Already formatted === END

    // Remove currency symbols ($, AU$, etc) === START
    currency = Object.values(currencySymbols).reduce((str, symbol) => {
      const hasSymbol = str.includes(symbol);
      return hasSymbol ? str.substring(symbol.length).trim() : str;
    }, currency);
    // Remove currency symbols ($, AU$, etc) === END

    // Format the currency string === START
    currency = parseFloat(currency)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: codes.USD,
        currencyDisplay: 'code',
        maximumFractionDigits: 2,
      })
      .substring(codes.USD.length) // removing the code since we only use it in some places
      .trim();
    const changeIndex = currency.indexOf('.00');
    if (changeIndex > 0) currency = currency.substring(0, changeIndex);
    // Format the currency string === END

    return currency;
  },
};
