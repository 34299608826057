import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardFooter,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SmallHeader,
} from '@axiom/ui';

import { WindowUtil } from '../../utils/WindowUtil';

import { OuterSpacer } from './ExpiredLinkStyles';
import { ExpiredLinkYieldSign } from './ExpiredLinkYieldSign';

const { getUrlWithRelayState } = WindowUtil;

export const ExpiredLink = () => {
  const location = useLocation();
  const path = '/';
  const loginScreen = getUrlWithRelayState(path, {
    location,
  });

  return (
    <OuterSpacer width="871px">
      <Card>
        <CardSection name="EXPIRED" divider>
          <Layout stackableOn="mobile">
            <LayoutItem rightGutter="24px">
              <ExpiredLinkYieldSign />
              <Gutter only="mobile" bottom="24px" />
            </LayoutItem>
            <LayoutItem>
              <Gutter gutterType="margin" bottom="16px">
                <SmallHeader>Expired Link</SmallHeader>
              </Gutter>
              <Paragraph>
                That link has expired or has already been used. Please use the
                option below to restart the sign in process.
              </Paragraph>
            </LayoutItem>
          </Layout>
        </CardSection>
        <CardFooter>
          <Button name="back-button" to={loginScreen}>
            Back to Sign In
          </Button>
        </CardFooter>
      </Card>
    </OuterSpacer>
  );
};
