import React from 'react';
import { string } from 'prop-types';
import { Row, Navbar, NavbarBrand } from 'reactstrap';
import { AxiomLogo, Button } from '@axiom/ui';

import Footer from '../../components/Footer/Footer';

import {
  ErrorBoundaryLayoutWrapper,
  ErrorBoundarySorryText,
} from './ErrorBoundaryLayoutStyles';

export const ErrorBoundaryLayout = ({ errorMessage }) => (
  <div>
    <div>
      <Navbar className="navbar axiom-navbar fixed-top navbar-expand-lg navbar-light bg-white keep">
        <NavbarBrand href="/">
          <AxiomLogo height="45px" />
        </NavbarBrand>
      </Navbar>
    </div>
    <Row>
      <ErrorBoundaryLayoutWrapper>
        <h1>
          <b>Oops! {!!errorMessage && <>{errorMessage}!</>}</b>
        </h1>
        <ErrorBoundarySorryText>
          Sorry for the inconvenience! This page is currently inaccessible.
          Please check back at a later time, and feel to reach out to your Axiom
          contact for further detail.
        </ErrorBoundarySorryText>
        <Button
          onClick={() => {
            document.location.href = '/';
          }}
        >
          Home
        </Button>
      </ErrorBoundaryLayoutWrapper>
    </Row>
    <Footer />
  </div>
);

ErrorBoundaryLayout.propTypes = {
  errorMessage: string,
};
ErrorBoundaryLayout.defaultProps = {
  errorMessage: null,
};
