import { z } from 'zod';

import { PositionSchema } from './positions';
import { AfcSubmissionCandidateOpportunitySchema } from './candidate-opportunities';

export const AfcSubmissionPositionSchema = PositionSchema.pick({
  billingHoursPerDay: true,
  billingHoursPerWeek: true,
  description: true,
  estimatedEngagementTotalMonths: true,
  id: true,
  name: true,
  role: true,
  reservedType: true,
  talentExperienceYears: true,
  worksite: true,
}).extend({
  candidateOpportunities: z.array(AfcSubmissionCandidateOpportunitySchema),
});
