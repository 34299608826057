import React from 'react';
import { bool, number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const RadioIcon = ({ alt, checked, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 28 28"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <path d="M14 .5C6.55.5.5 6.55.5 14S6.55 27.5 14 27.5 27.5 21.45 27.5 14 21.45.5 14 .5zm0 1c6.91 0 12.5 5.59 12.5 12.5S20.91 26.5 14 26.5 1.5 20.91 1.5 14 7.09 1.5 14 1.5z" />
    {!!checked && <circle cx="14" cy="14" r="7" />}
  </svg>
);

RadioIcon.propTypes = {
  alt: string,
  checked: bool,
  className: string,
  height: number,
  name: string,
  width: number,
};

RadioIcon.defaultProps = {
  alt: null,
  checked: false,
  className: null,
  height: 28,
  name: 'RADIO_ICON',
  width: 28,
};
