import Ajv from 'ajv';

import { axiomValidationOptions } from './options';
import { PracticeAreaSchema } from './practice-area';

const ajv = new Ajv(axiomValidationOptions());

export const PracticeAreaWithParentSchema = PracticeAreaSchema.extend({
  parent: PracticeAreaSchema.nullish(),
});

const practiceAreaWithParentValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      maxLength: 255,
    },
    bullhornId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    parentId: {
      type: ['string', 'null'],
    },
    type: {
      type: ['string', 'null'],
    },
    parent: {
      type: ['object', 'null'],
      additionalProperties: false,
      properties: {
        name: {
          type: 'string',
          maxLength: 255,
        },
        bullhornId: {
          type: ['string', 'null'],
          maxLength: 255,
        },
        parentId: {
          type: ['string', 'null'],
        },
        type: {
          type: ['string', 'null'],
        },
      },
      required: ['name'],
    },
  },
  required: ['name'],
};

export const practiceAreaWithParentValidator = ajv.compile(
  practiceAreaWithParentValidation
);
