import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const LocationIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    width={width}
    height={height}
    viewBox="0 0 10 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    {!!alt && <title>{alt}</title>}
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1_MyProfile" transform="translate(-809.000000, -363.000000)">
        <g id="Group-20" transform="translate(535.000000, 92.000000)">
          <g id="Group-12" transform="translate(272.000000, 246.000000)">
            <g id="Group-44" transform="translate(2.000000, 24.000000)">
              <path
                d="M10,5.7366 C10,8.3616 5.247,14.2336 5.247,14.2336 C5.247,14.2336 0.495,8.3616 0.495,5.7366 C0.495,3.1116 2.623,0.9836 5.247,0.9836 C7.872,0.9836 10,3.1116 10,5.7366"
                id="Fill-40"
                fill="#FF5A2D"
              />
              <path
                d="M7.1196,5.5925 C7.1196,6.6265 6.2816,7.4645 5.2466,7.4645 C4.2136,7.4645 3.3746,6.6265 3.3746,5.5925 C3.3746,4.5585 4.2136,3.7195 5.2466,3.7195 C6.2816,3.7195 7.1196,4.5585 7.1196,5.5925"
                id="Fill-42"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LocationIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

LocationIcon.defaultProps = {
  alt: null,
  className: null,
  height: 14,
  name: 'LOCATION_ICON',
  width: 10,
};
