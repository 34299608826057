import React, { PureComponent } from 'react';
import { bool, func, oneOf, oneOfType, number, string, node } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

import { CheckboxWithLabelRaw } from './CheckboxWithLabelRaw';
import { CheckboxWithLabelWrapper, CWLLabel } from './CheckboxWithLabelStyles';

class CheckboxWithLabel extends PureComponent {
  handleChange = e => {
    const { onValueChange } = this.props;
    onValueChange(e.target.checked);
  };

  render() {
    const {
      handleChange,
      props: {
        checked,
        disabled,
        id,
        label,
        name,
        type,
        value,
        className,
        ...rest
      },
    } = this;

    delete rest.onValueChange;

    return (
      <CheckboxWithLabelWrapper
        disabled={disabled}
        data-test={DataTestUtil.format(
          name || id || label || 'CHECKBOX_CONTAINER'
        )}
        className={className}
      >
        <CheckboxWithLabelRaw
          checked={checked}
          onValueChange={handleChange}
          data-test={DataTestUtil.format('CHECKBOX')}
          data-value={checked ? 'checked' : 'unchecked'}
          inputProps={{
            ...rest,
            id,
            name: name || id,
            type,
            value,
            disabled,
          }}
        />
        {!!label && (
          <CWLLabel data-test={DataTestUtil.format('LABEL_TEXT')} htmlFor={id}>
            {label}
          </CWLLabel>
        )}
      </CheckboxWithLabelWrapper>
    );
  }
}

CheckboxWithLabel.defaultProps = {
  disabled: false,
  checked: false,
  className: undefined,
  name: undefined,
  onValueChange: () => {},
  type: 'checkbox',
  value: undefined,
};

CheckboxWithLabel.propTypes = {
  checked: bool,
  className: string,
  disabled: bool,
  id: string.isRequired,
  label: oneOfType([string, node]).isRequired,
  name: string,
  type: oneOf(['radio', 'checkbox']),
  value: oneOfType([bool, number, string]),
  onValueChange: func,
};

export { CheckboxWithLabel };
