import { OpportunitiesConst } from './opportunity';
import { StateCodesConst } from './state-codes';

const { StateCodes } = StateCodesConst;

const nestedMappings = ['opportunity.practiceArea', 'account.industry'];

const booleanFilterFields = ['opportunity.isExcludedFromFeed'];

const defaultSort = 'opportunity.createdAt';

const defaultSortOrder = {
  'opportunity.salesCloseDate': 'desc',
  [defaultSort]: 'desc',
};

const { Stages } = OpportunitiesConst;

const statesNotAvailableForNonReservedPositions = [
  StateCodes.CA,
  StateCodes.IL,
  StateCodes.OR,
  StateCodes.PA,
];

const defaultPageSize = 10;
const defaultStageFilter = [Stages.Craft, Stages.Compete];

const visibilityOptions = {
  id: 1,
  position: {
    name: 1,
    description: 1,
    role: 1,
    endDate: 1,
    startDate: 1,
    worksite: 1,
    weeklyTimeUnit: 1,
    billingUnitsPerWeek: 1,
    estimatedEngagementTotalMonths: 1,
    calculatedTalentType: 1,
    billingHoursPerWeek: 1,
  },
  opportunity: {
    id: 1,
    accountName: 1,
    jobName: 1,
    stage: 1,
    locationCityStateCountry: 1,
    description: 1,
    practiceAreaId: 1,
  },
  account: {
    calculatedName: 1,
    website: 1,
  },
  practiceArea: {
    name: 1,
  },
  isSaved: 1,
  candidateStatus: 1,
  addedToPoolAt: 1,
  candidateIsInterested: 1,
  rejectionLossCode: 1,
};

const Phases = {
  New: 'New',
  Submitted: 'Submitted',
  Interviewing: 'Interviewing',
  Closed: 'Closed',
} as const;

export type OpportunityPhaseType = (typeof Phases)[keyof typeof Phases];

export const PositionCalculatedTalentTypes = {
  Lawyer: 'Lawyer',
  NonLawyer: 'Paralegal or other',
} as const;

export type PositionCalculatedTalentType =
  (typeof PositionCalculatedTalentTypes)[keyof typeof PositionCalculatedTalentTypes];

export const CandidateOccupationTypes = {
  Lawyer: 'Lawyer',
  LawyerFlex: 'Lawyer-Flex',
  LegalSupport: 'Legal Support',
  LegalSupportFlex: 'Legal Support-Flex',
  LawyerClientReferred: 'Lawyer-Client Referred',
  Paralegal: 'Paralegal',
} as const;

export type CandidateOccupationType =
  (typeof CandidateOccupationTypes)[keyof typeof CandidateOccupationTypes];

// java enums to be used for translating java DAOs and ESDaos from js code

// aligns with java enum TimeCommitment.java
const TimeCommitment = {
  Between1And10: '1-10',
  Between11And20: '11-20',
  Between21And30: '21-30',
  Between31And40: '31-40',
  MoreThan40: '40+',
};

export const WorkFeedConst = {
  nestedMappings,
  booleanFilterFields,
  defaultSort,
  defaultSortOrder,
  defaultPageSize,
  defaultStageFilter,
  statesNotAvailableForNonReservedPositions,
  visibilityOptions,
  Phases,
  TimeCommitment,
};
