import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const PiggyBankIcon = ({
  width,
  height,
  alt,
  backgroundFill,
  pigColor,
  className,
  name,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-test={DataTestUtil.format(name)}
    >
      {!!alt && <title>{alt}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle fill={backgroundFill} cx="15" cy="15" r="15" />
        <path
          d="M15.0705394,7.593361 C17.1116907,7.593361 19.0095728,8.04907713 20.5898999,8.83102816 C22.2705677,7.63932157 23.7541016,7.67716369 25.0401107,8.94325854 L25.0401107,8.94325854 L23.2293343,10.7423793 C24.264551,11.8352787 24.9119826,13.1455498 25.0230296,14.5627945 C25.5039682,15.0908886 26.0812315,15.5232997 26.7538766,15.861392 L26.7538766,15.861392 L26.7538766,18.617007 L21.9337962,20.9897493 L20.1502688,24.2659719 L17.559858,24.2659719 L17.5595992,22.1739344 C16.7639845,22.3258491 15.9299614,22.406639 15.0705394,22.406639 C14.1503166,22.406639 13.2592136,22.3140136 12.4132009,22.1406277 L12.4132749,24.2659719 L9.84825004,24.2659719 L7.43893386,19.766048 C5.97994049,18.4779495 5.10096819,16.8152341 5.10096819,15 C5.10096819,10.9094262 9.56449727,7.593361 15.0705394,7.593361 Z"
          fill={pigColor}
        />
        <circle fill="#FFFFFF" cx="22.1950207" cy="15" r="1" />
        <line
          x1="25.7455048"
          y1="8.7219917"
          x2="22.3803323"
          y2="12.183119"
          stroke="#FFFFFF"
          strokeWidth="1.41666667"
          strokeLinecap="round"
        />
        <path
          d="M5.10096819,15 C3.56128435,14.9005188 2.73390885,14.1284008 2.61884167,12.6836461"
          stroke={pigColor}
          strokeWidth="1.41666667"
          strokeLinecap="round"
        />
        <path
          d="M12.8603043,10.4652275 C13.8724851,10.1495433 14.6316206,9.99170124 15.137711,9.99170124 C15.6438014,9.99170124 16.3327114,10.1495433 17.2044408,10.4652275"
          stroke="#FFFFFF"
          strokeWidth="1.41666667"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

PiggyBankIcon.defaultProps = {
  width: 30,
  height: 30,
  alt: null,
  backgroundFill: '#fff',
  pigColor: '#FF5A2D',
  className: null,
  name: 'Piggy_Bank_Icon',
};

PiggyBankIcon.propTypes = {
  alt: string,
  backgroundFill: string,
  className: string,
  height: number,
  name: string,
  pigColor: string,
  width: number,
};
